import React, {useState} from "react";
import "./openPosition.scss";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

const Data = [
  {
    id: 1,
    heading: "What is in it for you",
    data: [
      {
        content:
          "Opportunity to work with a world class team of business consultants and engineers solving some of the most complex business problems by applying data and analytics techniques.",
      },
      {
        content:
          "Fast track career growth in a highly entrepreneurial work environment. ",
      },
      {
        content: "Best-in-industry renumeration package.",
      },
    ],
  },

  {
    id: 2,
    heading: "Essential Technical Skills",
    data: [
      {
        content:
          "Technical expertise with emerging Big Data technologies, such as: Python, Spark, Hadoop, Clojure, Git, SQL and Databricks; and visualization tools: Tableau and PowerBI.",
      },
      {
        content:
          "Experience with cloud, container and micro service infrastructures. ",
      },
      {
        content:
          "Experience working with divergent data sets that meet the requirements of the Data Science and Data Analytics teams.",
      },
      {
        content:
          "Hands-on experience with data modelling, query techniques and complexity analysis.",
      },
    ],
  },

  {
    id: 3,
    heading: "Desirable Skills",
    data: [
      {
        content:
          "Experience/Knowledge of working in an agile environment and experience with agile methodologies such as Scrum.",
      },
      {
        content:
          "Experience of working with development teams and product owners to understand their requirement. ",
      },
      {
        content: "Certifications on any of the above areas will be preferred.",
      },
    ],
  },

  {
    id: 4,
    heading: "Your duties will include",
    data: [
      {
        content:
          "Develop data solutions within a Big Data Azure and/or other cloud environments. ",
      },
      {
        content:
          "Working with divergent data sets that meet the requirements of the Data Science and Data Analytics teams.",
      },
      {
        content:
          "Build and design Data Architectures using Azure Data factory, Databricks, Data lake, Synapse.",
      },
      {
        content:
          "Liaising with CTO, Product Owners and other Operations teams to deliver engineering roadmaps showing key items such as upgrades, technical refreshes and new versions. ",
      },
      {
        content:
          "Perform data mapping activities to describe source data, target data and the high-level or detailed transformations that need to occur.",
      },
      {
        content:
          "Assist Data Analyst team in developing KPIs and reporting in tools viz. Power BI, Tableau.",
      },
      {
        content: "Data Integration, Transformation, Modelling.",
      },
      {
        content: "Maintaining all relevant documentation and knowledge bases.",
      },
      {
        content:
          "Research and suggest new database products, services and protocols.",
      },
    ],
  },
  {
    id: 5,
    heading: "Essential Personal Traits",
    data: [
      {
        content:
          "You should be able to work independently and communicate effectively with remote teams. ",
      },
      {
        content:
          "Timely communication/escalation of issues/dependencies to higher management.",
      },
      {
        content:
          "Curiosity to learn and apply emerging technologies to solve business problems.",
      },
    ],
  },
];

const data = [
  {
    text: 'Home',
    url: '/'
  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]


function DataEngineer() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div id="homePage" className="openPosition-box">
      <Navbar />
      <GoogleAnalytics/>

      <HeaderSection text1="Data Engineer" picture={banner} button = {true}  />

      <div className="position-main">
        <div className="position-body">

        <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex'}}>
            <a href={ele.url}className="content" key={index}>
              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'white'}}>/</div>}
            </div>
          ))}
        </div>

          <div className="top">
            <div className="heading">
              <span>
                <b>Job description</b>
              </span>
            </div>
            <div className="position">
              <span>Position: Data Engineer</span>
            </div>
            <div className="desired">
              <span>Desired experience: 3-8 years</span>
            </div>
          </div>
          <hr
            style={{
              height: "5px",
              backgroundColor: "#bb1ccc",
              border: "none",
            }}
          />
          <div className="heading1">
            <div className="content">
              You will act as a key member of the Data consulting team, working
              directly with the partners and senior stakeholders of the clients
              designing and implementing big data and analytics solutions.
              Communication and organisation skills are keys for this position,
              along with a problem-solution attitude.
            </div>
          </div>
          <div className="heading2-list">
            {Data.map((item) => (
              <>
                <div className="heading">
                  <span>{item.heading}</span>
                </div>
                <div className="list">
                  <ul>
                    {item.data.map((item1) => (
                      <li>{item1.content}</li>
                    ))}
                  </ul>
                </div>
              </>
            ))}
          </div>

          <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
          </div>
        </div>
      </div>
      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Data Engineer"/>
      <Footer />
    </div>
  );
}

export default DataEngineer;
