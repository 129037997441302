import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/blogs-page-supply-chain.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

function SupplyChainAnalytics() {

  useEffect(() => {
        document.title = "Data analytics for supply chain";
        document.getElementsByTagName("META")[3].content =
          "For supply chain networks to operate as reliably and efficiently, analytics implementation may prove to be essential. Know how:";

  })

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];
  return (
    <div id="homePage">
      <Helmet>
        <meta
          name="keywords"
          content="Data analytics, supply chain analytics"
        />
      </Helmet>

      <Navbar  bg="black" />
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
            <h1 className="main-heading">
              {" "}
              How is analytics capable of making the supply chain more reliable
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p
                style={{
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
                className="heading-content"
              >
                Supply chains are the backbone of successful organisations.
                These complex webs of interconnected networks help in delivering
                superior customer experiences, reducing operational costs, and
                integrating stakeholders across the value chain. No wonder,
                every organisation desire to have vibrant, dynamic, and reliable
                supply chain networks capable of reducing operational costs and
                improving profitability.
              </p>

              <p className="heading-content">
                The application of analytics can prove crucial to achieving the
                desired levels of reliability and efficiency in supply chain
                networks. It brings a layer of intelligence on top of the
                existing information, enhancing understanding. By collecting,
                analysing, and simplifying supply chain data, businesses can
                significantly improve forecasting accuracy, route scheduling,
                and the decision-making capabilities of organisations. Further,
                the insights from the analysis can also prove helpful in
                identifying bottlenecks and potential disruptions so that
                proactive measures can be taken well in advance.
              </p>

              <p className="heading-content">
                How else the application of analytics on the existing
                information can make supply chains more reliable, here are the
                complete details:
              </p>

              <h1 className="heading">1) Inventory Optimisation:</h1>

              <p className="heading-content">
                By analysing historical data and current trends, businesses can
                identify inventory patterns and predict future demand. This
                enables them to optimize inventory levels and avoid overstocking
                or understocking. Additionally, data experts use analytics to
                identify slow-moving or excess inventory, enabling businesses to
                take action to reduce waste and free up valuable storage space.
              </p>

              <h1 className="heading">
                2) Improved Supply Planning and Demand Forecasting:
              </h1>

              <p className="heading-content">
                Analytics capabilities help businesses in optimizing their
                supply planning and improving their demand forecasting. By
                utilizing AI-enabled analytics, businesses can gain better
                visibility into stock levels, freshness, and overall supply
                plans, which enables them to make informed decisions and take
                necessary actions.
              </p>

              <p className="heading-content">
                n addition, businesses can assess product availability and
                suitability based on factors such as geography, seasonality,
                brand, technology, market, etc. ensuring visibility on service
                level and stock coverage to meet their customers’ needs.
                Furthermore, data driven demand visibility enables the smooth
                running of production schedules, optimal inventory management,
                and timely delivery of finished products to customers.
              </p>

              <h1 className="heading">3) Proactive Risk Management:</h1>

              <p className="heading-content">
                With analytics capabilities, businesses can empower their teams
                to identify potential risks in the supply chain caused by
                various factors. By analysing historical data and current market
                conditions, firms can develop predictive models to anticipate
                potential disruptions and take pre-emptive measures to mitigate
                their impact.
              </p>

              <p className="heading-content">
                Additionally, by analysing factors such as their financial
                stability, reputation, and performance history, businesses can
                assess the supplier risks. By identifying high-risk suppliers,
                business stakeholders can take steps to diversify their supplier
                base and reduce their overall exposure to risk.
              </p>

              <p className="heading-content">
                Furthermore, businesses are also deploying analytics to monitor
                and improve supply chain performance, such as reducing lead
                times, improving on-time delivery rates etc… By continuously
                monitoring and analyzing supply chain data, firms can make
                data-driven decisions to improve their risk management
                strategies and ensure the resilience and continuity of their
                supply chain operations.
              </p>

              <h1 className="heading">4) Improving Working Capital:</h1>

              <p className="heading-content">
                By analyzing data from different sources, businesses can
                identify clusters of late payments from customers, early
                payments to vendors, overstocking situations that create cash
                flow blockades. By following an analytics approach in different
                KPIs: a) improving DSO (Days Sales Outstanding), b) improving
                inventory levels, c) optimizing vendor payment, d) managing
                short-term cash flow deficits, etc., businesses can track,
                manage, and improve their working capital efficiency.
              </p>

              <h1 className="heading">
                5) Improving Transparency and Agility:
              </h1>

              <p className="heading-content">
                Firms can monitor their supply chain operations effectively,
                gaining complete visibility into the processes, workflows, and
                key performance indicators. This transparency helps firms
                identify bottlenecks and inefficiencies in the supply chain,
                which can be addressed promptly to minimize the impact on the
                overall operations.
              </p>

              <p className="heading-content">
                Furthermore, by following an analytics-driven approach, firms
                can build agile supply chain systems that can respond quickly to
                changes in the market, customer demands, or disruptions in the
                supply chain. By leveraging advanced analytics techniques such
                as predictive modelling and machine learning, firms can develop
                contingency plans to mitigate the impact of any disruption.
              </p>

              <h1 className="main-heading"> Conclusion</h1>

              <p className="heading-content">
                By developing a data driven approach in supply chain management
                can have a major impact on businesses. Companies can optimize
                inventory levels, improve supply planning, and forecast demand
                in a scientific and methodical way. This allows businesses to
                reduce operational costs, improve efficiency, and deliver better
                customer experiences.
              </p>

              <p
                className="heading-content"
                style={{
                  fontSize: " 20px",
                  paddingTop: "2rem",
                  fontStyle: "italic",
                }}
              >
                The article was originally published in
                <a
                  style={{ color: "#bb1ccc", paddingLeft: "10px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://cio.economictimes.indiatimes.com/news/big-data/avinash-sidha-aays-analytics-on-reducing-supply-chain-costs-in-cpg-firms-through-data-driven-insights/99975669"
                >
                  ETCIO
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default SupplyChainAnalytics;
