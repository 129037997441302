export const freq1 = [
    {
        id: 1,
        title: " How can organizations leverage artificial intelligence (AI) to improve decision-making processes?",
        description:
          "Organizations can leverage AI to enhance decision-making by using data-driven insights, automating routine tasks, and predicting future trends. AI-driven systems analyze voluminous amounts of data to decode patterns and trends that may not be immediately apparent, enabling more accurate and timely decisions.",
      },
      {
        id: 2,
        title: "How do ML and AI help businesses use their enterprise data effectively? ",
        description:
          "ML and AI help businesses use their enterprise data effectively by identifying actionable insights, optimizing processes, and predicting outcomes. These technologies allow enterprises to make data-driven decisions, improve efficiency, and gain a competitive edge. ",
      },
      {
        id: 5,
        title: "How is AI used in business decision-making?",
        description:
          "AI is used in business decision-making to analyze data, predict outcomes, and automate decisions. It helps firms make more informed decisions by providing real-time insights, optimizing operations, and reducing human biases.",
      },
      {
        id: 4,
        title: "What examples are there of AI being used to support decision-making?",
        description:
          "Examples of AI being used as decision-making support include predictive analytics for market trends, AI-driven risk assessments, and forecasting etc. AI is also used in customer relationship management (CRM) to provide personalized recommendations and improve customer satisfaction.",
      },
];
export const freq2 = [

  {
    id: 3,
    title: "What is the role of big data and AI artificial intelligence in operations decision-making?",
    description:
      "Big data and AI play a crucial role in operations decision-making by providing deep analytical insights, automating processes, and optimizing supply chains. These technologies empower businesses to make informed decisions that improve efficiency and reduce costs. ",
  },
      {
        id: 6,
        title: "What are the applications of artificial intelligence in decision-making?",
        description:
          "AI applications in decision-making include risk assessment, predictive analytics, customer segmentation, and supply chain optimization. AI can also automate routine tasks, freeing human resources for more strategic activities.",
      },
      {
        id: 7,
        title: "What are the benefits of AI decision-making?",
        description:
          "The benefits of AI decision-making include improved accuracy, efficiency, and speed. AI enables businesses to make data-driven decisions, cut op-ex, and respond quickly to market changes.",
      },

];