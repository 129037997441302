import React from "react";

function NewsRoomReuse(props) {
    return (
      <>
        <div className="newsroom-reuse-body">
          <div className="newsroom-reuse-main  ">
            <div className="newsroom-reuse-section">
              <div
                className="newsroom-reuse-box"
                style={{
                  backgroundColor: `${props.color}`,
                  minHeight: `${props.minHeight}`,
                }}
              >
                <h2>{props.heading}</h2>

                <h3>{props.title}</h3>

                <p className="text_overFlow">{props.content}</p>
              </div>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href={props.redirectURL}
                    target="_blank"
                    rel="noreferrer"
                  >
              <div className="news-btn-read-more">
                <div>
                    Read More
                </div>
              </div>
                  </a>
            </div>
          </div>
        </div>
      </>
    );
}

export default NewsRoomReuse;
