import React from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/ind_Blogs_Microsoft_Gold_partner.jpg";
import microsoftGold from "../../assets/img/MicrosoftGold.png";

import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

function MicrosoftGoldPartners() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };
  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];
  return (
    <div id="homePage">
      <Navbar bg="black"/>
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
            <h1 className="main-heading">
              {" "}
              Aays Analytics is now a Microsoft gold partner in data analytics
              and data platform
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p className="heading-content">
                We are excited to announce that Aays Analytics has now achieved
                Microsoft gold partnership in data analytics and data platform.
                While we have been helping our clients to deploy Business
                Intelligence, Advanced Analytics and Big Data solutions using{" "}
                <a
                  target="_blank"
                  href="../azure-analytics-and-power-bi-services.html"
                  style={{ display: "inline-block", color: "#E67D1C" }}
                >
                  associated Azure technologies
                </a>
                – Azure Databricks, SQL Server, Synapse Analytics, Power BI etc,
                this just brings an official endorsement to our expertise.
              </p>
              <p className="heading-content">
                <img
                  class="mobile_image"
                  style={{ width: "30%" }}
                  src={microsoftGold}
                  alt="imgdata"
                />
              </p>

              <h1 className="main-heading">
                Benefits that our customers garner through this partnership
              </h1>
              <p className="heading-content">
                Gold status in the Microsoft Partner Network is an important
                milestone for our products and services. With these
                partnerships,{" "}
                <a
                  target="_blank"
                  style={{ display: "inline-block", color: "#E67D1C" }}
                  href="/"
                >
                  our clients
                </a>{" "}
                can rely on Aays to deploy industry leading data solutions that
                complies to the best standards in the market along with{" "}
                <a
                  style={{ display: "inline-block", color: "#E67D1C" }}
                  target="_blank"
                  href="/"
                >
                  Aays’s USP{" "}
                </a>{" "}
                – Business context and agility. These certifications include
                both on-premises and cloud technologies, and cover everything
                from conventional Business Intelligence to Big Data solutions.
              </p>

              <p className="heading-content">
                Aays Analytics' CEO Anshuman Bhar states, “We chose to build our
                operational technology infrastructure through our collaboration
                with Microsoft and deploying our products and services on
                state-of-the-art environments for our clients. This proves our
                ability to scale and address the slew of challenges issued by
                our customers in the complex corporate finance space. Microsoft
                Gold Certification clearly demonstrates that Aays Analytics
                continues to seek and implement industry-leading standards that
                set us apart from the competition and help us to stand out as a
                leader in data analytics solution providers.”
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default MicrosoftGoldPartners;
