import Section5 from "../../pageLayout/CustomerSuccessStoryLayout/Section5";
import { ResourceData } from "../../constant/ResourceData";
const Section8 = () => {
    return (
      <>
        <div className="aadi-section8">
            <Section5 data = {ResourceData} category = "aadi"  />
        </div>
      </>
    );
}

export default Section8;