import React, {useState} from "react";
import HeaderSection from "../../reuseLayout/HeaderSection";
import banner from "../../assets/img/DataEngBanner.png";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import ApplyJobs from "./ApplyJobs";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

const Data1 = [
  {
    id: 1,
    heading: "About Aays",
    content:
      "Aays is a fast-growing analytics consulting firm. We are solving some of the most complex corporate finance problems faced by large and fast-growing companies in the world utilising big data, cloud computing and AI/ML. We are an employee first company – we firmly believe that happy employees can create happy customers and that can in turn result in a successful business. Come and join us for a life changing journey.",
  },
  {
    id: 2,
    heading: "Your team",
    content:
      "You will act as a key member of the consulting team helping Clients to re-invent their corporate finance function by leveraging advanced analytics. You will be closely working directly with senior stakeholders of the clients designing and implementing data strategy in finance space which includes multiple use cases viz. controllership, FP&A and GPO. You will be responsible for developing technical solutions to deliver scalable analytical solutions leveraging cloud and big data technologies. You will also collaborate with Business Consultants and Product Owners to design and implement technical solutions. Communication and organisation skills are keys for this position.",
  },
];
const Data2 = [
  {
    id: 1,
    heading: "Responsibilities",
    data: [
      {
        content:
          "Design and drive end to end data and analytics solution architecture from concept to delivery",
      },
      {
        content:
          "Design, develop, and support conceptual/logical/physical data models for analytics solutions.",
      },
      {
        content:
          "Ensures that industry-accepted data architecture principles, standards, guidelines and concepts are integrated with those of allied disciplines and coordinated roll-out and adoption strategies are in place.",
      },
      {
        content:
          "Drive the design, sizing, setup, etc. of Azure environments and related services",
      },
      {
        content:
          "Provide mentoring on data architecture design and requirements to development and business teams",
      },
      {
        content:
          "Reviews the solution requirements and architecture to ensure selection of appropriate technology, efficient use of resources and integration of multiple systems and technology.",
      },
      {
        content:
          "Advising on new technology trends and possible adoption to maintain competitive advantage",
      },
      {
        content:
          "Participate in pre-sales activities and publish thought leaderships",
      },
      {
        content:
          "Work closely with the founders to drive the technology strategy for the organization",
      },
      {
        content:
          "Help and lead technology team recruitments in various areas of data analytics",
      },
    ],
  },
  {
    id: 2,
    heading: "Experience Needed:",
    data: [
      {
        content: "Demonstrated experience delivering multiple data solutions",
      },
      {
        content:
          "Demonstrated experience with ETL development both on-premises and in the cloud using SSIS, Data Factory, and related Microsoft and other ETL technologies.",
      },
      {
        content:
          "Demonstrated in depth skills with SQL Server, Azure Synapse, Azure Databricks, HD Insights, Azure Data Lake with the ability to configure and administrate all aspects of SQL Server.",
      },
      {
        content:
          "Demonstrated experience with different data models like normalized, de- normalized, stars, and snowflake models. Worked with transactional, temporal, time series, and structured and unstructured data.",
      },
      {
        content:
          "Data Quality Management (Microsoft DQS and other data quality and governance tools) and Data Architecture standardization experience",
      },
      {
        content:
          "Deep understanding of the operational dependencies of applications, networks, systems, security and policy (both on-premise and in the cloud; VMs, Networking, VPN (Express Route), Active Directory, Storage (Blob, etc.), Windows/Linux).",
      },
      {
        content:
          "Advanced study / knowledge in the field of computer science or software engineering along with advanced knowledge of software development and methodologies (Microsoft development lifecycle including OOP principles, Visual Studio, SDKs, PowerShell, CLI).",
      },
      {
        content:
          "Is familiar with the principles and practices involved in development and maintenance of software solutions and architectures and in-service delivery (Microsoft and Azure DevOps. Azure Automation).",
      },
      {
        content:
          "Has strong technical background and remains evergreen with technology and industry developments",
      },
      {
        content:
          "Hands-on expertise and willingness to code in Spark, Python, SQL, ability to pick up new languages and tech on the go.",
      },
    ],
  },
];

const data = [
  {
    text: 'Home',
    url: '/'
  },
  {
    text: 'View all Jobs',
    url: '/career'
  }
]

function DataArchitect() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div id="homePage" className="openPosition-box">
      <Navbar />
      <GoogleAnalytics/>

      <HeaderSection text1="Data Architect" picture={banner} button={true} />

      <div className="position-main">
        <div className="position-body">

        <div className="back-button">
          {data.map((ele, index) => (
            <div style={{display: 'flex'}}>
            <a href={ele.url}className="content" key={index}>
              {ele.text}
            </a>
            {index !== data.length - 1 && <div style={{color: 'white'}}>/</div>}
            </div>
          ))}
        </div>

          <div className="top">
            <div className="heading">
              <span>
                <b>Job description</b>
              </span>
            </div>
            <div className="position">
              <span>Position: Data Architect</span>
            </div>
            <div className="desired">
              <span>Desired experience: 3-8 years</span>
            </div>
          </div>
          <hr
            style={{
              height: "5px",
              backgroundColor: "#bb1ccc",
              border: "none",
            }}
          />

          {Data1.map((item) => (
            <>
              <div className="heading1">
                <div className="heading">
                  <span>
                    <b>{item.heading}</b>
                  </span>
                </div>
                <div className="content">{item.content}</div>
              </div>
            </>
          ))}

          <div className="heading2-list">
            {Data2.map((item) => (
              <>
                <div className="heading">
                  <span>{item.heading}</span>
                </div>
                <div className="list">
                  <ul>
                    {item.data.map((item1) => (
                      <li>{item1.content}</li>
                    ))}
                  </ul>
                </div>
              </>
            ))}
          </div>
          <div className="apply-now">
              <button onClick={() => setOpenModal(true)}>Apply Now</button>
          </div>
        </div>
      </div>

      <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} position="Data Architect"/>
      <Footer />
    </div>
  );
}

export default DataArchitect;
