import React from "react";
import img1 from "../../assets/img/supply-chain-page-img-1.png";
import img2 from "../../assets/img/supply-chain-page-img-2.png";
import img3 from "../../assets/img/supply-chain-page-img-3.png";
import img4 from "../../assets/img/supply-chain-page-img-4.png";
import img5 from "../../assets/img/supply-chain-page-img-5.png";
import img6 from "../../assets/img/supply-chain-page-img-6.png";
import img7 from "../../assets/img/supply-chain-page-img-7.png";
import img8 from "../../assets/img/supply-chain-page-img-8.png";
import SectionReuseBox from "./SectionReuseBox";

export const sectionData = [
  {
    id: 1,
    heading: "Demand Planning",
    content: "Strategically anticipate market needs with precision.",
    redirectURL:
      "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    image: img1,
  },
  {
    id: 2,
    heading: "End-to-End Inventory Coverage",
    content: "Ensure comprehensive oversight across the inventory lifecycle.",
    redirectURL: "/customer-success-stories/inventory-optimization-global-CPG",
    image: img2,
  },
  {
    id: 3,
    heading: "Factory and Market Visibility",
    content: "Gain clear insights into both production and market dynamics.",
    redirectURL: "/customer-success-stories/inventory-optimization-global-CPG",
    image: img3,
  },
  {
    id: 4,
    heading: "Prime Cost",
    content: "Optimize costs by refining primary expense factors effectively.",
    redirectURL:
      "/customer-success-stories/prime-cost-visibility-global-CPG-success-story",
    image: img4,
  },
  {
    id: 5,
    heading: "RM-OM Visibility",
    content: "Enhance transparency from raw materials to output management.",
    redirectURL:
      "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    image: img5,
  },
  {
    id: 6,
    heading: "Vendor Payments",
    content: "Streamline and optimize vendor payment processes for efficiency.",
    redirectURL:
      "/customer-success-stories/vendor-payments-optimization-case-study",
    image: img6,
  },
  {
    id: 7,
    heading: "Purchase Price Variance",
    content: "Effectively manage and mitigate fluctuations in purchase prices.",
    redirectURL: "",
    image: img7,
  },
  {
    id: 8,
    heading: "Supply Chain Network Optimization",
    content: "Optimize supply chains for efficiency and agility.",
    redirectURL:
      "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    image: img8,
  },
];

function Section1() {
  return (
    <div className="section1-body">
      <div className="supply-padding">
        <div className="supply-spacing">
          <h1>Resilient Supply Chain Solutions that We Offer </h1>

          <div className="flexContainer">
            {sectionData.map((data, index) => (
              <SectionReuseBox
                key={index}
                heading={data.heading}
                picture={data.image}
                content={data.content}
                redirectURL={data.redirectURL && data.redirectURL}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
