import React from "react";
import contactUsBottomBanner from "../../assets/img/contactUs-bottomBanner.png";

function Section2() {
  return (
    <div className="contact-padding section2-main">
      <div className="main-heading-text"> Our Presence</div>
      <div className="background-box">
        <div
          style={{
            background: `url(${contactUsBottomBanner})`,
            width: "100%",
            height: "400px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
          }}
          className="location-background "
        >
          {/* <img src={contactUsBottomBanner} alt="contact" /> */}

          <div className="locations">
            <div className="locations-flexContainer">
              <div className="container1">
                <div className="heading">United Kingdom</div>

                <span className="content">
                  9 Townfield, <br />
                  Rickmansworth,
                  <br />
                  England, WD3 7FN
                </span>
              </div>
              <div className="container2">
                <div className="heading">Singapore</div>
                <span className="content">
                  68 Circular Road, <br />
                  #02-01,Singapore,
                  <br />
                  049422
                </span>{" "}
              </div>
              <div className="container3">
                <div className="heading">
                  India 
                </div>

                <span className="content">
                  PC - 101, Block 4A, DLF Corporate Park, Phase III, MG Road,
                  DLF City, Gurgaon, Haryana, 122002
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
