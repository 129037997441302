import badge1 from "../../assets/img/Aadi/Badge-01.png";
import badge2 from "../../assets/img/Aadi/GenAI_Service_Seasoned_Vendors_3.png";
import Carousel from "react-multi-carousel";
import { useMediaQuery, useTheme } from "@mui/material/";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Section7 = () => {
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <div className="aadi-section7">
        <div className="main">
          <div className="heading">
            <span>
              <span className="pink">Recognized by</span> <br />
              <span className="bold">Top Industry Analysts</span>
            </span>
          </div>

          {isMobile ? (
            <Carousel
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              arrows={true}
              autoPlay={true}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              // showDots={isMobile ? false : true}
            >
              {/* // first item */}
              <div className="badge-box">
                <div className="image">
                  <img src={badge1} alt="Aays_Finance Co-Pilot" />
                </div>
                {/* <div className="badge-heading">
                  AIM - Leader - Data <br />
                  Engineering
                </div> */}
                <div className="content">
                  Learn why Aays was named as a Leader in the AIM Penetration
                  and Maturity Quadrant for Top Data Engineering Service
                  Providers 2024
                </div>
                <div className="read-btn">
                  <a href="/aim-ranks-aays-a-leader-in-data-engineering-service-providers">
                    <button>Read More</button>
                  </a>
                </div>
              </div>

              {/* // second item */}

              <div className="badge-box">
                <div className="image">
                  <img src={badge2} alt="Aays_Finance Co-Pilot" />
                </div>
                {/* <div className="badge-heading">
                  AIM - Seasoned Vendor -  <br />
                  Gen AI
                </div> */}
                <div className="content">
                  Learn why Aays was named as a Seasoned Vendor in the AIM
                  Penetration and Maturity Quadrant for Top Gen AI Service
                  Providers 2023
                </div>
                <div className="read-btn">
                  <a href="/gen-ai-seasoned-vendor-aim-research">
                    <button>Read More</button>
                  </a>
                </div>
              </div>
            </Carousel>
          ) : (
            <div className="flex-container">
              <div className="left">
                <div className="badge-box">
                  <div className="image">
                    <img src={badge1} alt="Aays_Finance Co-Pilot" />
                  </div>
                  {/* <div className="badge-heading">
                  AIM - Leader - Data <br />
                  Engineering
                </div> */}
                  <div className="content">
                    Learn why Aays was named as a Leader in the AIM Penetration
                    and Maturity Quadrant for Top Data Engineering Service
                    Providers 2024
                  </div>
                  <div className="read-btn">
                    <a href="/aim-ranks-aays-a-leader-in-data-engineering-service-providers">
                      <button>Read More</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="badge-box">
                  <div className="image">
                    <img src={badge2} alt="Aays_Finance Co-Pilot" />
                  </div>
                  {/* <div className="badge-heading">
                  AIM - Seasoned Vendor -  <br />
                  Gen AI
                </div> */}
                  <div className="content">
                    Learn why Aays was named as a Seasoned Vendor in the AIM
                    Penetration and Maturity Quadrant for Top Gen AI Service
                    Providers 2023
                  </div>
                  <div className="read-btn">
                    <a href="/gen-ai-seasoned-vendor-aim-research">
                      <button>Read More</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Section7;
