import React, { useEffect } from "react";
import "../assets/css/ourteams.scss";
import image1 from "../assets/img/OUR-TEAMS-Image-2.png";
import image2 from "../assets/img/our-teams-group-meetup.jpg";
import image3 from "../assets/img/our-teams-freshers.jpg";
import image4 from "../assets/img/CEO-Anshuman.jpg";
import image5 from "../assets/img/delhi-office-team.png";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const OurTeams = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div
      style={{
        background: "#000000",
       // position: "relative",//
        zIndex: "1",
        // paddingTop: "6rem",
        // paddingBottom: "6rem",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "wrap",
        paddingTop:'10rem'
      }}
      className="ourTeamsMaincls"
    >
      <div className="marginTeams margin-box">
        <div
          className="main-heading"
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: "600",
            paddingBottom: "1rem",
            fontFamily: "Montserrat  ",
            textAlign: "center",
          }}
        >
          Our Teams
        </div>

        <div className="sub-heading">
          <p>
          At Aays, we're all about people, passion, and making things happen.
          Meet the awesome individuals who breathe life into our projects and
          make our journey unforgettable.</p>
        </div>

        <div class="contaner">
          <div
            class="item"
            // data-aos="fade-left"
          >
            <img loading="lazy" src={image1} alt="" />
          </div>
          <div class="item item-flex">
            <img
              loading="lazy"
              class="item-flex-img-big"
              // data-aos="fade-left"
              src={image2}
              alt="3"
            />
            <img
              loading="lazy"
              class="item-flex-img"
              // data-aos="fade-left"
              src={image5}
              alt="4"
            />
          </div>
          <div
            class="item item-flex"

            // data-aos="fade-up"
          >
            <img loading="lazy" class="item-flex-img" src={image3} alt="1" />
            <img
              loading="lazy"
              // data-aos="fade-left"
              class="item-flex-img-big"
              src={image4}
              alt="2"
            />
          </div>
        </div>

        <div className="know-more-btn">
          <a href="/about">
            <button>Know More</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default OurTeams;
