import React from "react";
import Footer from "../nav/Footer";
import Navbar from "../nav/NavBar";

const Pagenotfound = () => {
  return (
    <div
      style={{ background: "black", height: "100vh" }}
      id="homePage"
      className="pageNotFound"
    >
      <Navbar />

      <div
        style={{
          height: "55vh",
          color: "white",
          paddingTop: "6rem",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "700",
          fontSize: "30px",
          alignContent: "center",
          textAlign: "center",
          gridGap: "10px",
        }}
      >
        <span>404 page not found</span>
        <div style={{ fontSize: "20px", color: "#bb1ccc" }}>
          <a style={{ color: "#bb1ccc" }} href="/">
            Click here to redirect
          </a>
        </div>
      </div>
      <div style={{ position: "fixed", bottom: "0px", width: "100%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Pagenotfound;
