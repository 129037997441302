export const fre1 = [
    {
        id: 1,
        title: " How is AI/ML used in supply chain optimization?",

        description:
          "AI is used in supply chain optimization by analyzing data to identify inefficiencies, predict demand, and automate processes. AI algorithms can optimize routes, manage inventory, and improve decision-making, leading to cost savings and enhanced performance across the supply chain.",
      },
      {
        id: 6,
        title: "How does AI contribute to sustainability and efficiency in supply chains?",
        description:
          "AI contributes to sustainability and efficiency in supply chains by optimizing routes, reducing energy consumption, and minimizing waste. AI systems can analyze data to identify the most efficient transportation routes, manage resources more effectively, and ensure that operations are conducted in an environmentally friendly manner.",
      },
      {
        id: 3,
        title: "How is AI transforming supply chain management?",
        description:
          "AI in supply chain management refers to applying artificial intelligence technologies to optimize and automate supply chain processes. This includes tasks such as demand forecasting, inventory management, logistics, and transportation, all of which benefit from AI's ability to analyze data and make informed decisions.",
      },
      
];
export const fre2 = [

  {
    id: 4,
    title: "How does AI improve demand forecasting in supply chains?",
    description: "AI improves demand forecasting by analyzing large datasets, including historical sales data, market trends, and external factors, to predict future demand more accurately. This allows companies to optimize inventory levels, reduce stockouts, and improve customer satisfaction.",
  },
      
      {
        id: 5,
        title: "What setbacks do firms face when implementing AI in their supply chains?",
        description:
          "Companies face several challenges when implementing AI in their supply chains, including data quality issues, high implementation costs, the complexity of integrating AI systems, and the need for change management and employee training. Addressing these setbacks is essential for successful AI adoption",
      },
    
      {
        id: 2,
        title: "How is ML used in the supply chain? ",
        description:
          "Machine learning (ML) is used in supply chain management to analyze historical data, identify patterns, and make predictions. ML algorithms can enhance demand forecasting, optimize inventory levels, and improve the accuracy of supply chain planning, resulting in more efficient and effective operations.",
      },

];