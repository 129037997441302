import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = () => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-YKZR62P5S9';
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-YKZR62P5S9');
        `;
    document.head.appendChild(script2);

    // Cleanup scripts on component unmount
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return (
    <Helmet>
      {/* Additional meta tags, if needed
      <meta name="description" content={`${description}`} />
      <meta name="keywords" content={`${keywords}`} />
      <meta property='og:title' content={`${title}`} />
      <meta property='og:image' content='https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png' /> */}
    </Helmet>
  );
};

export default GoogleAnalytics;
