import { thirdAccordion } from "./AccordionData";
import AccordionFile from "./AccordionFile";
import gif1 from "../../assets/img/Aadi/thirdScreenGif.gif.gif";
import { useMediaQuery, useTheme } from "@mui/material/";
import mobileGif from "../../assets/img/Aadi/mobile-section5-gif.gif";

const Section5 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <div id="section5" className="aadi-section5 scroll-section">
        <div className="main">
          <div className="heading">AI for Information Synthesis</div>
          <div className="text">
            Establish cause-effect type of relationship in the data along with
            user defined thresholds to incorporate events of significance and
            materiality.
          </div>
          <div className="flex-container">
            <div className="left">
              <div className="accordian-file">
                <AccordionFile data={thirdAccordion} />
              </div>
            </div>
            <div className="right">
              {isMobile ? (
                <img src={mobileGif} alt="Aays_Finance Co-Pilot" />
              ) : (
                <img src={gif1} alt="Aays_Finance Co-Pilot" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section5;
