import React from "react";
import designElement from "../../assets/img/careerPageDesignElement.png";
import logo1 from "../../assets/img/career-company-logo1.jpg";
import logo2 from "../../assets/img/career-company-logo2.png";
import logo3 from "../../assets/img/career-company-logo3.png";
import logo4 from "../../assets/img/career-company-logo4.png";
import logo5 from "../../assets/img/career-company-logo5.png";
import logo6 from "../../assets/img/career-company-logo6.png";
import logo7 from "../../assets/img/career-company-logo7.png";
import logo8 from "../../assets/img/career-company-logo8.png";
import logo9 from "../../assets/img/career-company-logo9.png";

const logosData = [
  {
    id: 1,
    img: logo1,
  },
  {
    id: 2,
    img: logo2,
  },
  {
    id: 3,
    img: logo3,
  },
  {
    id: 4,
    img: logo4,
  },
  {
    id: 5,
    img: logo5,
  },
  {
    id: 6,
    img: logo6,
  },
  {
    id: 7,
    img: logo7,
  },
  {
    id: 8,
    img: logo8,
  },
  {
    id: 9,
    img: logo9,
  },
];

function Section2() {
  return (
    <div className="career-section2">
      <center data-aos="fade-up">
        <span className="spanHeading">
          Elevate Your Data Science Career Here
        </span>
        <div className="content">
          We have been recognised as the best firm for data scientists to work
          for. You would elevate your data science career in a workplace that is
          endorsed by industry accolades.
        </div>
        <div className="spanLast">
          <span>
            Our team brings a wealth of experience from these global companies
          </span>
        </div>
      </center>

      <center>
        <div className="company-logos">
          {logosData.map((data) => (
            <div className="company-logos-items">
              <img
                loading="lazy"
                style={{ width: "80px" }}
                src={data.img}
                alt="logo3"
                key={data.id}
              />
            </div>
          ))}
        </div>
      </center>

      <div className="background-design">
        <img loading="lazy" src={designElement} alt="Background-img" />
      </div>
    </div>
  );
}

export default Section2;
