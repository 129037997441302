import React from "react";
import icon1 from "../../assets/img/1.png";
import icon2 from "../../assets/img/2.png";
import icon3 from "../../assets/img/3.png";
import icon4 from "../../assets/img/4.png";
import icon5 from "../../assets/img/5.png";

const icons = [icon1, icon2, icon3, icon4, icon5];
const texts = [
  {
    id: 1,
    text1: "Data Engineering",
  },

  {
    id: 2,
    text1: "Governance",
  },

  {
    id: 3,
    text1: "DataOps",
  },
  {
    id: 4,
    text1: "Data Mesh",
  },

  {
    id: 5,
    text1: "Data Architecture",
    text2: "& Design",
  },
];

const Section2 = () => {
  return (
    <div className="secondpage_section">
      <div className="secondpage_section-body margin-box ">
        <h1
          style={{ paddingBottom: "1.3rem", fontSize: "30px" }}
          className="secondpage_section-header-text"
        >
          Data Engineering Excellence for Agile Insights
        </h1>

        <div className="dataeng-section2-new-css">
          {icons.map((icon, index) => (
            // <div key={index}>
            //    <img loading = "lazy"    style={{ width: "60px", height: "60px" }} src={icon} alt={`icon${index + 1}`} />
            //   <span className='dataeng-section1-text-new'>{texts[index]}</span>
            // </div>
            <div className="icon-box">
              <span className="image-div">
                <img
                  src={icon}
                  alt={`icon${index + 1}`}
                  className="feature-icon"
                />
              </span>
              <span className="text">
                {texts[index].text1} <br /> {texts[index].text2}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section2;
