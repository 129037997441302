import aadiGif from "../../assets/img/Aadi/aaaaa 1.png";
// import aadiGif from "../../assets/img/Aadi/Aadi-logo-gif.gif";
import { useMediaQuery, useTheme } from "@mui/material";

const Section2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div className="aadi-section2" id="aadi-section2">
        <div className="main">
          <div className="flex-container">
            <div className="left">
              <div className="image">
                <img src={aadiGif} alt="Aays_Finance Co-Pilot" />
              </div>
            </div>
            <div className="right">
              {/* <div className="heading">
                <span>Why</span> AaDi
              </div> */}
              <div className="subHeading">
                A Decision Intelligence Co-Pilot for {!isMobile && <br />}{" "}
                Finance function
              </div>
              <div className="text">
                Discover the power and intelligence of Finance Co-Pilot in a
                highly distributed enterprise ecosystem with complex
                cross-functional information exchange. Bring down information
                silos and perform root-cause analysis, flux reporting, variance
                / bridge analysis, spot anomalies powered with agentic
                workflows, knowledge graphs, Q&A and conversation capabilities.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section2;
