import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import mobileBannerImage from "../assets/img/supply-chain-banner-right.png";
import "../pageLayout/SupplyChainLayout/SupplyChain.scss";
import Section1 from "../pageLayout/SupplyChainLayout/Section1";
import Section2 from "../pageLayout/SupplyChainLayout/Section2";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import Navbar from "../nav/NavBar";
import Footer from "../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomePageSection5 from "../pageLayout/HomePageLayout/HomePageSection5";
import CustomerSuccessSection from "../pageLayout/AboutPageLayout/Section5";
import { CustomerSuccessStoriesData } from "../constant/CustomerSuccessStoriesData";
import { Helmet } from "react-helmet";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import Section2Mobile from "../pageLayout/CustomerSuccessStoryLayout/Section2Mobile";

import { sectionData } from "../pageLayout/SupplyChainLayout/Section1";
import GoogleAnalytics from "../../app/GoogleAnalytics";
import BottomBanner from "../reuseLayout/BottomBanner";

const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + (75 * $(window).height()) / 100);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - (75 * $(window).height()) / 100);
};

function SupplyChain() {
  useEffect(() => {
    document.title = "Aays | Supply Chain Analytics Solutions";
    // document.getElementsByTagName("META")[3].content =
    //   "Explore AI-led supply chain analytics solutions offering demand planning, inventory management, cost analysis, AI resiliency tools, and sustainable practices. ";
  });

  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div id="homePage" className="supplychain-main-class">
      <Helmet>
        <meta
          name="keywords"
          content="Aays, Aays Analytics, Supply chain analytics, Supply Chain Optimization, AI supply chain solutions"
        />
      </Helmet>
      <Navbar />
      <GoogleAnalytics />

      {isMobile ? (
        <NewHeaderSectionMobile
          text1=" Transforming Ecosystems By Building AI-Powered Supply Chains"
          subtext1="Advanced Solutions for"
          subtext2="Building Supply Chain Resilience"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1=" Transforming Ecosystems By Building"
          text2="AI-Powered Supply Chains"
          subtext1="Advanced Solutions for"
          subtext2="Building Supply Chain Resilience"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1=" Transforming Ecosystems By Building"
          text2="AI-Powered Supply Chains"
          subtext1="Advanced Solutions for"
          subtext2="Building Supply Chain Resilience"
          picture={mobileBannerImage}
        />
      )}
      <MiddleBanner text="Delivered over USD 2Bn Business Impact in 2022-23" />
      <Section2 isIpad={isIpad} />
      {isMobile ? (
        <Section2Mobile
          data={sectionData}
          heading="Resilient Supply Chain Solutions that We Offer"
        />
      ) : (
        <Section1 />
      )}
      {/* <Section1 /> */}

      <div style={{ backgroundColor: "black" }}>
        {!isMobile && !isIpad ? (
          <CustomerSuccessSection
            data={CustomerSuccessStoriesData}
            tag="supplyChain"
          />
        ) : (
          <HomePageSection5
            data={CustomerSuccessStoriesData}
            tag="supplyChain"
            show="true"
          />
        )}
      </div>

      <BottomBanner contentChange={true} />
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default SupplyChain;
