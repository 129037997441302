import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function CustomerSectionBox1(props) {
  return (
    <div className="success-story-page-section-3-grid-items">
       <a href={props.redirectURL} target="_blank" rel="noreferrer">
      <img
        loading="lazy"
        style={{
          filter: "grayscale(1)",
        }}
        src={props.picture}
        alt="finance"
      /> 
      </a>
     
      {props.date && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            fontSize: "12px",
            padding: "10px 5px",
          }}
          className="reuseBoxHover"
        >
          <div>{props.date} </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              gridGap: "5px",
            }}
          >
            <AccessTimeIcon
              style={{
                fontSize: "14px",
              }}
            />{" "}
            <span>{props.read}</span>{" "}
          </div>
        </div>
      )}
      <div className={props.class}>{props.title}</div>
      <a
        style={{ textDecoration: "none", color: "white" }}
        href={props.redirectURL}
        target="_blank"
        rel="noreferrer"
      >
        <div className="btn-read-more">
          <div>Read More</div>
        </div>
      </a>
    </div>
  );
}

export default CustomerSectionBox1;
