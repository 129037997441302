import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/inventory-case-studies.jpg"

const storyData = {
  title:
    "Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
  content:
    "The goal was to improve and refine the inventory management processes, which was achieved through insights that enhanced inventory visibility, freshness, and coverage. The comprehensive inventory management solution implemented by Aays improved stock analysis significantly, resulting in benefits like prioritizing demand and segments and identifying expiring stocks for planning pricing/markdown strategies, among others.",
  subTitle1: "$220 Mn",
  subText1: "worth of stock outages identified, prioritizing demand across 450 items",
  subTitle2: "$11 Mn",
  subText2: "worth of expiring stock identified, prioritizing sales/ markdown strategies",
  imageData: picture
};

function Inventory_Analytics() {

  useEffect(() => {
    document.title = "Enhanced CPG Inventory Management | Aays";
    document.getElementsByTagName("META")[3].content="Aays optimized inventory management for a global CPG, enhancing visibility, and demand prioritization. Learn about stock analysis and expiring stock strategies.";
  });

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Inventory transformation, inventory management, inventory visibility, stock analysis, expiring stocks, demand prioritization, pricing strategies."
        />
      </Helmet>

      <div>
        <Indicusstomersuccessstories data = {storyData} />
      </div>

      {/* <Resources/> */}
      <Footer />

    </div>
  );
}

export default Inventory_Analytics;
