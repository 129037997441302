import { Helmet } from "react-helmet";
import { useEffect } from "react";
import $ from "jquery";
import Navbar from "../../../nav/NavBar";
import Footer from "../../../nav/Footer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import "./newBlog.scss";
import "./newBlogResponsive.scss";
import aaysRanking from "../../../assets/img/Aays Ranking FT High GrowthCompanies.png";

  const data = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "All Blogs",
      url: "/Blog",
    },
  ];


const FT = () => {
  useEffect(() => {
    document.title =
      "Aays Among High Growth Companies Asia Pacific | Financial Times";
    document.getElementsByTagName("META")[3].content =
      "Aays ranked 31st in Financial Times and Statista's High Growth Companies Asia-Pacific 2024 list. Learn about our impressive growth and commitment to AI and analytics solutions driving business transformation.";
  });
  return (
    <>
      <div id="homePage">
        <Helmet>
          <meta
            name="keywords"
            content="Aays Analytics, Aays, Top companies Asia Pacific, Financial Times and Statista, High Growth Companies, High Growth Companies Asia-Pacific"
          />
        </Helmet>

        <Navbar bg="black" />
        <div className="newBlog-body">
          <div className="newBlog-margin">
            <div className="newBlog-main">
              <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back" >
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
              <div className="firstSection">
                <div className="main">
                  <h1 className="heading">
                    Aays Listed in 500 High Growth Companies Asia Pacific 2024
                    by Financial Times and Statista
                  </h1>

                  <div className="date-read-container">
                    <div className="date-container">
                      <div className="date"> July 4, 2024 </div>
                      <div className="read">
                        <AccessTimeIcon
                          style={{
                            fontSize: "14px",
                          }}
                        />{" "}
                        <span> 2 min read</span>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="box-form FT-background"></div>
                  <div className="content1">
                    <div className="text1">
                      We are pleased to announce that Aays has been recognized
                      by the Financial Times and Statista in their prestigious
                      list of 500 High Growth Companies Asia-Pacific 2024,
                      featuring companies that have generated more than $1
                      million in revenue.
                    </div>
                    <div className="text1">
                      With an overall rank of 31st, we are also proud to be
                      ranked 1st in the management consulting category.
                    </div>
                  </div>
                </div>
              </div>

              <div className="quotes">
                <div className="main">
                  <div className="content">
                    <p className="content1">
                      The report highlights our impressive absolute growth rate
                      of 1777.8% and a CAGR of 165.8%, underscoring our position
                      as an emerging leader in the Asia-Pacific business
                      landscape.
                    </p>
                    {/* <p className="content2">
                      The report provides an in-depth analysis of the
                      employee-centric policies implemented by top companies,
                      focusing on creating exemplary work environments for data
                      scientists. The top indices based on which the employers
                      were evaluated were: Learning & Support, Productivity &
                      Engagement, Benefits & Well-being, Rewards & Excellence,
                      and Diversity & Inclusion. Read the complete{" "}
                      <a href="https://aimresearch.co/penetration-maturity-pema-matrix/top-data-engineering-service-providers-pema-quadrant-2024">
                        <span className="link">Report here</span>
                      </a>
                      .
                    </p> */}
                    <div className="image">
                      <img
                        src={aaysRanking}
                        alt="Aays_High Growth Companies_Financial Times"
                      />
                    </div>

                    <p className="content2">
                      This recognition also highlights our leadership in driving
                      business transformation through advanced data and AI
                      solutions. As a leading management consulting firm, Aays
                      is dedicated to setting industry standards and has
                      delivered over $2 billion in business impact across
                      various sectors.
                    </p>

                    <p className="content2">
                      Our expertise spans the entire data and AI value chain,
                      from architecture setup to insight generation, and
                      includes generative AI capabilities.
                    </p>

                    <p className="content-last">
                      For more details about the ranking and the complete list
                      of FT High Growth Companies Asia Pacific, visit the{" "}
                      <a href="https://www.ft.com/high-growth-asia-pacific-ranking-2024" target="_blank"
    rel="noopener noreferrer">
                        <span
                          style={{
                            color: "#bb1ccc",
                          }}
                          className="link"
                        >
                          {" "}
                          Financial Times High Growth Companies Ranking Page.
                        </span>
                      </a>
                    </p>
                  </div>
                  {/* <div className="container">
                    <div className="quote-box">
                      <div className="heading">
                        Dwarika Patro, COO & Co-Founder, Aays
                      </div>
                      <div className="content">
                        <span className="size">“</span> Our incredible team
                        members are the core of everything we do. We are
                        committed to consistently offering them growth
                        opportunities and competitive benefits to support their
                        overall career growth and well-being.{" "}
                        <span className="size">”</span>
                      </div>
                    </div>
                    <div className="quote-box">
                      <div className="heading">
                        Anshuman Bhar, CEO & Co-Founder, Aays
                      </div>
                      <div className="content">
                        <span className="size">“</span> This recognition
                        highlights our focus on employee engagement, well-being,
                        and providing the right opportunities for learning and
                        development. A big thank you to the entire team for
                        making this possible.
                        <span className="size">”</span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FT;
