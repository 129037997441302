import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/Innovation-Analytics-main-page-case-study.png";

const storyData = {
  title:
    "How a CPG conglomerate leveraged advanced analytics to uncover the value of innovation and assess the impact of new product launches",
  content:
    "A multi-billion-dollar global consumer goods company sought to measure the true value of its innovations and the value of incremental sales generated from new product launches. Facing the challenge of understanding the impact on sales while considering competition and potential cannibalization effects, the company turned to advanced analytics to drive their decision-making process. Through the power of data and ML, they could achieve an effective measurement and tracking, portfolio transformation, and improvised assessment of cannibalization, leading to enhanced decision-making, and overall innovation value for the organization.",
  subTitle1: "Ability",
  subText1: "to measure and track innovations",
  subTitle2: "Assessment",
  subText2: "of cannibalization, incrementality and competitive steal",
  imageData: picture
};

function Innovation_Analytics() {

  useEffect(() => {
    document.title = "CPG Innovation Analytics: Value Assessment & New Product Impact | Aays";
    document.getElementsByTagName("META")[3].content="Discover how advanced analytics empowered a CPG conglomerate to assess innovation value and measure the impact of new product launches. Explore data-driven insights.";
  });

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Innovation analytics, incremental sales, portfolio transformation, cannibalization assessment"
        />
      </Helmet>

      <div>
        <Indicusstomersuccessstories data = {storyData} />
      </div>

      {/* <Resources/> */}
      <Footer />

    </div>
  );
}

export default Innovation_Analytics;
