import React from 'react';
import { useMediaQuery } from "@mui/material";
import { ReactComponent as ArrowIcon } from '../../assets/img/Arrow 1.svg';

function RightArrow({data = [], startIndex = '', setStartIndex = () => {}}) {
    const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
        defaultMatches: false, // Set defaultMatches to false
    });

    const handleRight = () => {
        setStartIndex((prevIndex) => Math.min(prevIndex + 1, data.length - (isIpad ? 2 : 3)));
    };

    const disablePointerEvents = data.length === startIndex + (isIpad ? 2 : 3);

    return (
        <div style={{
            display: 'flex', 
            alignItems: 'center',
            cursor: disablePointerEvents ? 'not-allowed' : 'pointer',
            pointerEvents: disablePointerEvents ? 'none' : 'auto',
            opacity: disablePointerEvents ? 0.5 : 1
        }} 
        onClick={handleRight}
        >
            <ArrowIcon width="100px" />
        </div>
    );
}

export default RightArrow;
