import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import supplyImg3 from "../../assets/img/Vendor Risk Management.png";
import dailySalesPositioning from "../../assets/img/Daily Sales Positioning .png";
import { ReactComponent as ReadMoreIcon } from "../../assets/img/read-more-arrow.svg";
import RightArrow from "./RightArrow";
import LeftArrow from "./LeftArrow";

const successStories = [
  {
    imgSrc: supplyImg3,
    altText: "finance",
    text: "Vendor Risk Management",
    content:
      "Managing vendor risks in a complex supply chain: Case study of a global consumer goods manufacturer",
    href: "/customer-success-stories/vendor-risk-management-case-study",
  },
  {
    imgSrc: dailySalesPositioning,
    altText: "finance",
    text: "Daily Sales Positioning",
    content:
      "Normalising daily sales position leveraging advanced smoothening and forecasting techniques",
    href: "/customer-success-stories/advanced-daily-sales-positioning-case-study",
  },
  {
    imgSrc: supplyImg3,
    altText: "finance",
    text: "Prime Cost",
    content:
      "Driving strategic initiatives through enhanced prime cost visibility: Success story of a global CPG company",
    href: "/customer-success-stories/prime-cost-visibility-global-CPG-success-story",
  },
];

function Section4() {
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad
    ? successStories.slice(startIndex, startIndex + 2)
    : successStories.slice(startIndex, startIndex + 3);

  return (
    <div
      id="caseStudySection4"
      className="success-story-page-section-4 section"
      style={{ position: "relative" }}
    >
      <div className="success-story-page-section-4-margin">
        <div className="success-story-page-section-4-Header-text">
          Operations
        </div>
        <div className="content">
          Explore how we enhance operations through insightful analytics,
          optimizing processes to streamline enterprises for maximum impact and
          improved efficiency
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {successStories.length > showLength && (
            <LeftArrow
              data={successStories}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}

          <div className="success-story-page-section-4-grid">
            {visibleItems.map((story, index) => (
              <div
                key={index}
                className="success-story-page-section-4-grid-items"
              >
                <img loading="lazy" src={story.imgSrc} alt={story.altText} />
                <div className="success-story-page-section-4-grid-items-text">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {story.text}

                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        letterSpacing: "0.2px",
                        lineHeight: "18px",
                        marginTop: "10px",
                        textAlign: "left",
                      }}
                    >
                      {story.content}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                    }}
                  >
                    <a
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="read-more-icon"
                      href={`${story.href}`}
                    >
                      <ReadMoreIcon width="50px" height="50px" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {successStories.length > showLength && (
            <RightArrow
              data={successStories}
              startIndex={startIndex}
              setStartIndex={setStartIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Section4;
