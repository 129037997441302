import React, { useState } from "react";
import Navbar from "../../nav/NavBar";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../CustomerSuccessStoryMoreLayout/CustomerSuccessStoryMore.scss";
import Alert from "@mui/material/Alert";
import AaysAnalyticsMarketingMixModelling from "../../Pdfs/AaysAnalytics_Marketing-Mix-Modelling.pdf";
import AccountReceivable from "../../Pdfs/Account-Receivable.pdf";
import AccountsPayable from "../../Pdfs/Accounts-payable.pdf";
import AIToolFinanceControllers from "../../Pdfs/AI-Tool-Finance-Controllers.pdf";
import DailyBusinessPositioning from "../../Pdfs/Daily-Business-Positioning.pdf";
import Innovationanalytics from "../../Pdfs/Innovation-analytics.pdf";
import InventoryAnalytics from "../../Pdfs/Inventory-Analytics.pdf";
import PrimeCost from "../../Pdfs/Prime-Cost.pdf";
import Supplychain from "../../Pdfs/Supply-chain.pdf";
import Vendorriskmanagement from "../../Pdfs/Vendor-risk-management.pdf";
import WorkingCapitalAnalytics from "../../Pdfs/Working-Capital-Analytics.pdf";
import GoogleAnalytics from "../../../app/GoogleAnalytics";

const Indicusstomersuccessstories = (props) => {
  const [isloading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  const rejectList = ["effem.com"];
  const getURl = new URL(window.location.href);
  const pathValue = getURl.pathname;

  console.log(pathValue, 'pathValue');

  const handleSubmit = (e) => {
    e.preventDefault();
    setisLoading(true);
    var URL =
      "https://kixepycste.execute-api.us-east-1.amazonaws.com/prod/contactus";

    var name = $("#name-input_new").val();
    var phone = "No phone number";
    var email = $("#email-input_new").val();
    var desg = $("#designation-input_new").val();
    var message = "";
    var company = $("#company-input_new").val();
    var splitArray = email.split("@");
    if (rejectList.indexOf(splitArray[1]) >= 0) {
      if (
        pathValue ===
        "/customer-success-stories/inventory-optimization-global-CPG"
      ) {
        message = "Inventory case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting"
      ) {
        message = "Supply chain case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/vendor-payments-optimization-case-study"
      ) {
        message = "Vendor payments case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics"
      ) {
        message = "Accounts receivables case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/working-capital-analytics-global-CPG"
      ) {
        message = "Working capital case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/prime-cost-visibility-global-CPG-success-story"
      ) {
        message = "Prime Cost case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/advanced-daily-sales-positioning-case-study"
      ) {
        message = "Daily Sales Positioning case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/ai-powered-tool-for-finance-controllers"
      ) {
        message = "AI Tool For Finance Controllers case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/analytics-to-assess-impact-of-innovation"
      ) {
        message = "Innovation Analytics case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/vendor-risk-management-case-study"
      ) {
        message = "Vendor Risk Management case study restricted";
      } else if (
        pathValue ===
        "/customer-success-stories/market-mix-modelling-snacks-food-manufacturer"
      ) {
        message = "View market modelling case study restricted";
      }
    } else {
      // Remove ".html" extension

      if (
        pathValue ===
        "/customer-success-stories/inventory-optimization-global-CPG"
      ) {
        message = "View Inventory case study";
      } else if (
        pathValue ===
        "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting"
      ) {
        message = "View Supply chain case study";
      } else if (
        pathValue ===
        "/customer-success-stories/vendor-payments-optimization-case-study"
      ) {
        message = "View Vendor payments case study";
      } else if (
        pathValue ===
        "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics"
      ) {
        message = "View Accounts receivables case study";
      } else if (
        pathValue ===
        "/customer-success-stories/working-capital-analytics-global-CPG"
      ) {
        message = "View Working capital case study";
      } else if (
        pathValue ===
        "/customer-success-stories/prime-cost-visibility-global-CPG-success-story"
      ) {
        message = "View Prime Cost case study";
      } else if (
        pathValue ===
        "/customer-success-stories/advanced-daily-sales-positioning-case-study"
      ) {
        message = "View Daily Sales Positioning case study";
      } else if (
        pathValue ===
        "/customer-success-stories/ai-powered-tool-for-finance-controllers"
      ) {
        message = "View AI Tool For Finance Controllers case study";
      } else if (
        pathValue ===
        "/customer-success-stories/analytics-to-assess-impact-of-innovation"
      ) {
        message = "View Innovation Analytics case study";
      } else if (
        pathValue ===
        "/customer-success-stories/vendor-risk-management-case-study"
      ) {
        message = "View Vendor Risk Management case study";
      } else if (
        pathValue ===
        "/customer-success-stories/market-mix-modelling-snacks-food-manufacturer"
      ) {
        message = "View Market Modelling case study";
      }
    }

    var contactUsData = {
      name: name,
      phone: phone,
      email: email,
      designation: desg,
      message: message,
      company: company,
    };

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(contactUsData),

      success: function () {
        // clear form and show a success message
        document.getElementById("landing_formid").reset();
        setisLoading(false);

        if (rejectList.indexOf(splitArray[1]) >= 0) {
          setisError(true);
          setTimeout(() => {
            setisError(false);
          }, 5000);
          return false;
        } else {
          if (
            pathValue ===
            "/customer-success-stories/inventory-optimization-global-CPG"
          ) {
            const filename = "AaysAnalytics_InventoryAnalytics";
            downloadFinanceController(InventoryAnalytics, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting"
          ) {
            const filename = "AaysAnalytics_SupplyChainAnalytics";
            downloadFinanceController(Supplychain, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/vendor-payments-optimization-case-study"
          ) {
            const filename = "AaysAnalytics_AccountsPayable";
            downloadFinanceController(AccountsPayable, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/global-CPG-strengthens-accounts-receivables-advanced-analytics"
          ) {
            const filename = "AaysAnalytics_AccountsReceivable";
            downloadFinanceController(AccountReceivable, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/working-capital-analytics-global-CPG"
          ) {
            const filename = "AaysAnalytics_WorkingCapitalAnalytics";
            downloadFinanceController(WorkingCapitalAnalytics, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/prime-cost-visibility-global-CPG-success-story"
          ) {
            const filename = "AaysAnalytics_PrimeCost";
            downloadFinanceController(PrimeCost, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/advanced-daily-sales-positioning-case-study"
          ) {
            const filename = "AaysAnalytics_Daily_Sales_Positioning";
            downloadFinanceController(DailyBusinessPositioning, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/ai-powered-tool-for-finance-controllers"
          ) {
            const filename = "AaysAnalytics_AI_Tool_Finance_Controllers";
            downloadFinanceController(AIToolFinanceControllers, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/analytics-to-assess-impact-of-innovation"
          ) {
            const filename = "AaysAnalytics_Innovation_Analytic";
            downloadFinanceController(Innovationanalytics, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/vendor-risk-management-case-study"
          ) {
            const filename = "AaysAnalytics_Vendor-Risk-Management";
            downloadFinanceController(Vendorriskmanagement, filename);
          } else if (
            pathValue ===
            "/customer-success-stories/market-mix-modelling-snacks-food-manufacturer"
          ) {
            const filename = "AaysAnalytics_Marketing-Mix-Modelling";
            downloadFinanceController(
              AaysAnalyticsMarketingMixModelling,
              filename
            );
          }
        }
      },
      error: function () {
        setisError(true);
        setisLoading(false);

        setTimeout(() => {
          setisError(false);
        }, 5000);
      },
    });
  };

  const downloadFinanceController = async (file, filename) => {
    console.log(file, filename);
    try {
        const response = await fetch(file);

        console.log(response, 'res');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        console.log(url, 'url');
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${filename}_${new Date().getTime()}.pdf`);

        document.body.appendChild(link);
        console.log(link, 'link');
        link.click();
    } catch (error) {
        console.error("Error downloading finance controller:", error);
    }
  };


  const data = [
    {
      text: 'Home',
      url: '/'
    },
    {
      text: 'All case studies',
      url: '/customer-success-stories'
    }
  ]

  return (
    <div id="homePage">
      <Navbar />
      <GoogleAnalytics/>

      <div className="page-width">
        <div className="indi-case-studies">
          <section style={{ height: "auto" }}>
            <div className="indi-case-studies-section1">

              <div className="back-button">
                {data.map((ele, index) => (
                  <div style={{display: 'flex'}}>
                  <a href={ele.url}className="content" style={{color:'white'}} key={index}>
                    {ele.text}
                  </a>
                  {index !== data.length - 1 && <div style={{color: 'white'}}>/</div>}
                  </div>
                ))}
              </div>

              <div className="main">
                <div style={{ paddingLeft: "inherit" }}>
                  <div>
                    <div>
                      <h1 className="main-heading">{props.data.title}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="indi-case-study-section2">
            <div className="section2-main">
              <div className="section2-body">
                <div className="section2-content-main">
                  <span>{props.data.content}</span>

                  <div className="grid-container1">
                    <div
                      className="left-container"
                      style={
                        props.data.subTitle2
                          ? { borderRight: "5px solid #eee" }
                          : null
                      }
                    >
                      <span className="heading">{props.data.subTitle1}</span>
                      <span className="content">{props.data.subText1}</span>
                    </div>

                    <div className="right-container">
                      <span className="heading">{props.data.subTitle2}</span>
                      <span className="content">{props.data.subText2}</span>
                    </div>
                  </div>

                  <div className="form-heading">
                    View the comprehensive case study
                  </div>

                  <div className="form-data-main">
                    <div
                     className="main"
                    >
                      <div>
                        <form
                          id="landing_formid"
                          className="form-data"
                          onSubmit={handleSubmit}
                        >
                          <div
                            className="form-field"
                          >
                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="name-input_new"
                                  className="input-field-landing"
                                  type="text"
                                  placeholder="Name"
                                  name="name"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="email-input_new"
                                  autoComplete="off"
                                  className="input-field-landing"
                                  type="email"
                                  placeholder="Email Address"
                                  name="email"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div
                          className="form-field"
                          >
                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="company-input_new"
                                  autoComplete="off"
                                  className="input-field-landing"
                                  type="text"
                                  placeholder="Company"
                                  name="company"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-input-boxes">
                              <div className="input-container-landing">
                                <input
                                  id="designation-input_new"
                                  autoComplete="off"
                                  className="input-field-landing"
                                  type="text"
                                  placeholder="Designation"
                                  name="designation"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="btn-read-more"
                          >
                            <button
                              style={{ cursor: "pointer" }}
                              disabled={isloading}
                              type="submit"
                            >
                              {isloading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span style={{ paddingLeft: "10px" }}>
                                    Downloading...
                                  </span>
                                </>
                              ) : (
                                "Download now"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="case-studies-header_img"
                  style={{
                    width: "37%",
                    height: "auto",
                    backgroundColor: "#3571CA",
                  }}
                >
                  <img
                    width="100%"
                    height="100%"
                    src={props.data.imageData}
                    alt="case-studies"
                  />
                </div>
              </div>
            </div>
          </section>

          <div onClick={nextstep} className="scroll-btn">
            <button className="arrowBtn">
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </button>
          </div>

          <div className="scroll-btn-down" onClick={nextstepDown}>
            <button className="arrowBtn">
              <KeyboardArrowUpIcon
                sx={{
                  fontSize: "40px",
                }}
              />
            </button>
          </div>

          {isError && (
            <div
              style={{
                position: "fixed",
                width: "auto",
                top: "12%",
                right: "10px",
              }}
            >
              <Alert severity="error">
                Sometime went wrong, Our team will connect you soon
              </Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Indicusstomersuccessstories;
