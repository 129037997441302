import React, { useState } from 'react'
import ApplyJobs from '../../pages/OpenPositions/ApplyJobs';

function Section7() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="career-section7-new padding-both">
      <div className="main">
        <center>
          <div className="heading">
            <span>Join Our Team </span>
          </div>
          <div className="content">
            <span>
              Ready to Thrive? Explore opportunities and join a team where your
              growth, well-being, and success are paramount. Start your journey
              with Aays today.
            </span>
          </div>
        </center>
        <div className="contact-wrapper">
          <button className="contact-us-btn applyJobsBtncls" onClick={() => setOpenModal(true)} >Apply Now</button>
  
          <ApplyJobs openModal={openModal} setOpenModal={setOpenModal} />
        </div>
      </div>
    </div>
  );
}

export default Section7