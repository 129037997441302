export const firstAccordion = [
  {
    id: 1,
    title: "Analyst Mind Graph Techniques",
    description:
      "Analyst mind graph techniques craft questions and responses in line with enterprise defined framework",
  },
  {
    id: 2,
    title: "Conversation Management ",
    description:
      "Handle multi-turn conversations, maintain context, ask relevant questions, and provide step-by-step assistance ",
  },
  {
    id: 3,
    title: "Root-Cause Analysis Planning ",
    description:
      "Employ planning algorithms to determine the optimal sequence to perform root-cause analysis and achieve conversation objectives ",
  },
  {
    id: 4,
    title: "Consistency and Rationality",
    description:
      "AI combined with heuristics based approach helps in driving consistency and rationality while interpreting questions",
  },
];
export const secondAccordion = [
  {
    id: 1,
    title: "Multiple Agent System Integration",
    description:
      "Incorporating multiple Agent system which is coordination of query understanding, data extractor, summarizer, charting agent.",
  },
  {
    id: 2,
    title: "Semantic Knowledge Base",
    description:
      "Using Vector Store to build a semantic knowledge base consisting of Table Metadata, Sample SQLs and Data Dictionary",
  },
  {
    id: 3,
    title: "Calculation Capabilities",
    description:
      "Incorporating calculation capabilities by writing tool scripts to call based on user intent or KPI required.",
  },
];
export const thirdAccordion = [
  {
    id: 1,
    title: "Focus on specific data/ patterns",
    description: "Finding a needle in the haystack ",
  },
  {
    id: 2,
    title: "Causal AI to Identify Root Cause of Events",
    description:
      "Incorporating causal ai to establish cause-effect type of relationship in the data ",
  },
  {
    id: 3,
    title: "Setting User defined Threshold Logic",
    description:
      "Incorporate user defined threshold logic to incorporate events that are significant from a materiality standpoint",
  },
];
export const fourthAccordion = [
  {
    id: 1,
    title: "Step Intelligence Maps (SIM)",
    description:
      "Step Intelligence Maps (SIM) to explain the chatbot's language model, retrieval strategies, and sequencing of response generation",
  },
  {
    id: 2,
    title: "Analyst Workbench",
    description:
      "Analyst workbench type feature to trace and understand the, calculations performed at each step, summarization etc. so as to almost minimize hallucinations",
  },
  {
    id: 3,
    title: "Feedback System",
    description:
      "Feedback System to incorporate user's remarks and ratings so as to improve the answers as per user defined response generation and question interpretation framework. ",
  },
];
export const frequentlyQuestionsPart1 = [
  {
    id: 1,
    title: "What exactly is Aadi?",
    description:
      "AaDi is a Decision Intelligence Co-Pilot for Finance function which can sit on top of multiple unconnected enterprise system of records and enable business analysts to perform root-cause analysis, flux reporting, variance / bridge analysis, spot anomalies on the fly powered with human like understanding and Q&A capabilities.",
  },
  {
    id: 2,
    title: " How is it different from Microsoft Co Pilot?  ",
    description:
      "Microsoft Copilot is an AI-powered digital assistant that aims to provide personalized assistance to users for a range of tasks and activities whereas AaDi is highly domain specific assistant which combines the power of LLMs, AI, finance knowledge and will be built on your own custom data.",
  },
  {
    id: 3,
    title: "How is it different from OpenAI based chatbots?  ",
    description:
      "OpenAI based chatbots are trained on massive datasets containing trillions of words sourced from a wide range of texts available on the internet whereas AaDi is primarily grounded and trained on enterprise finance meta-data and brings the knowledge and mind-map of a finance analyst.",
  },
  {
    id: 4,
    title: " Is it a chatbot? ",
    description: "The idea of a chatbot is not new. AaDi is much more than a chatbot. Not only it has conversation capabilities but it is trained in finance meta-data which is typically available from enterprise system of records. This makes it work like a virtual analyst which can perform day to day task of a finance analyst like MIS reporting, root cause analysis, P&L anomaly detection and much more.",
  },
  {
    id: 5,
    title:
      "Is it based on OpenAI APIs and other models available in the market?  ",
    description:
      "AaDi brings together connected power and intelligence of agentic workflows, knowledge graphs, mind map engines, LLMs and AI.",
  },
  {
    id: 6,
    title: "How do I try it out?  ",
    description:
      "Please register for a Demo and we are happy to provide a limited access to the tool where one can play with the tool to understand capabilities.",
  },
  {
    id: 7,
    title: " Can this tool (AaDi) directly work on my proprietary data?  ",
    description:
      "Yes, AaDi can be fully customised to work on any enterprise data based on your specific requirements. Our team can be engaged to configure and setup in accordance to your system of records.",
  },
];
export const frequentlyQuestionsPart2 = [
  {
    id: 8,
    title:
      "Can I build my own proprietary version of AaDi based on my enterprise requirements?  ",
    description:
      "Yes, The tool contextualises responses in line with enterprise defined framework and based on analyst mind-graph techniques.",
  },
  {
    id: 9,
    title: " How long does it take it implement for my organisation?  ",
    description:
      "Usually, a working prototype on your existing data can be developed in about 3 months time.",
  },
  {
    id: 10,
    title: " Is there a demo version I can play around with? ",
    description: "Yes, reach out to our team to create a demo environment.",
  },
  {
    id: 11,
    title:
      "My Organisation is having multiple ERP systems? Can this tool be integrated with our system of records which is complex?  ",
    description:
      "Yes, it is fully configurable and brings down information silos by its ability to connect and integrate multiple system",
  },
  {
    id: 12,
    title:
      " Can this solution be built entirely in my organisation’s cloud stack as per our defined AI stack?   ",
    description:
      "Our tool is developed as per Industry standard architecture. We will work with your enterprise architect to ensure this is built as per your enterprise architecture.",
  },
  {
    id: 13,
    title: " Is my enterprise data secured?   ",
    description:
      "Yes, this is fully compliant and will be developed based on your enterprise architecture and security considerations",
  },
];
