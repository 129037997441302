import { useEffect, useState } from "react";
import lightCircle from "../../assets/img/Aadi/lightCircle.png"
import ContactUs from "../../pageLayout/HomePageLayout/ContactUs";

const Section1 = () => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(()=>{
   setTimeout(() => {
    setOpenModal(true)
   }, 5000);
  },[])
    return (
      <>
        <div className="aadi-section1">
          <div className="main">
            <div className="flex-container">
              <div className="left">
                <div className="contentBox">
                  <div className="text">
                    <div className="content">
                      <span className="aadi-name">AaDi</span> <br /> A Decision
                      Intelligence <br /> Co-Pilot for Finance function
                    </div>
                  </div>
                  <div className="buttons">
                    <a href="#aadi-section2">
                      <button>Learn More</button>
                    </a>
                    <button onClick={() => setOpenModal(true)}>
                      Request a Demo
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                {/* <div className="image">
                  <img src={lightCircle} alt="Aays_Finance Co-Pilot" />
                </div> */}
              </div>
            </div>
            <ContactUs openModal={openModal} setOpenModal={setOpenModal} />
          </div>
        </div>
      </>
    );
}
export default Section1;