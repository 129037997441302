import React from "react";
import NewsRoomReuse from "./NewsRoomReuse";
function Section2() {
  return (
    <>
      <div className="newsroom-section2-body " id="newsroom-section2" >
        <div className="margin-box newsroom-padding">
          <h1>Press Release</h1>
          <div className="newsroom-section2-main ">
            <NewsRoomReuse
              heading="ANALYTICS INDIA MAGAZINE"
              title="Aays Analytics - One of the top 5 companies with highest expected CAGR in the next five years"
              content="We got featured in the 2022 list of Top Data Science Providers in India by Analytics India Magazine"
              color="#212121"
              redirectURL='https://analyticsindiamag.com/top-data-science-providers-in-india-2022-penetration-and-maturity-pema-quadrant/'

            />
            <NewsRoomReuse
              heading="PRESS RELEASE"
              title="Aays Analytics forays into APAC region; kick-starts operations in Singapore"
              content="By establishing our presence in Singapore, we aim to increase the company's reach in the SEA area with a hope to explore and create new business opportunities."
              color="#212121"
              redirectURL='/blogs/aays-in-singapore'

            />
            "
          </div>
        </div>
      </div>
    </>
  );
}

export default Section2;
