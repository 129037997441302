import { fourthAccordion } from "./AccordionData";
import AccordionFile from "./AccordionFile";
import gif1 from "../../assets/img/Aadi/process flow 2 3.gif";
import mobileGif from "../../assets/img/Aadi/mobile-aadi-infographic-section6.gif"
import { useMediaQuery, useTheme } from "@mui/material/";


const Section6 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <div id="section6" className="aadi-section4 scroll-section">
        <div className="main">
          <div className="heading">AI for Explainability</div>
          <div className="text">
            Explain response generation with Step intelligence map (SIM) to
            trace and understand the calculations performed at each step,
            summarization etc. Incorporate User remarks to further improve
            response generation and minimise Hallucinations
          </div>
          <div className="flex-container">
            <div
              style={{
                backgroundColor: "#121112",
              }}
              className="right"
            >
              {isMobile ? (
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={mobileGif}
                  alt="Aays_Finance Co-Pilot"
                />
              ) : (
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={gif1}
                  alt="Aays_Finance Co-Pilot"
                />
              )}
            </div>
            <div className="left">
              <div className="accordian-file">
                <AccordionFile data={fourthAccordion} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section6;
