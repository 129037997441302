import "./homeNewChanges.scss";
const AccoladesBox = ({ title, description, image }) => {
  return (
    <>
      <div id="accolades-box">
        <div className="image">
          <img src={image} alt="aays" />
        </div>
              <div className="heading">{ title}</div>
        {/* <div className="description">
         {description}
        </div> */}
      </div>
    </>
  );
};

export default AccoladesBox;
