import { ProductData } from "./productData";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const Product = () => {
    
    return (
        <div className="product-main">
            {
                ProductData.map((item, index) => {
                    return (
                        <div className="main-product-box">
                            <div className="circle">
                                <QueryStatsIcon sx={{color : "white", fontSize : "40px"}} />
                            </div>

                            <div key={index} className="product-body" >

             
                                <div className="product-details">
                                    <h2 className="product-heading">{item.title }</h2>
                            <div className="product-content">{item.description}</div>
                            <div className="view-button">
                                <a href={item.url}  target="_blank" rel="noreferrer" >
                                    <button>
                                        Overview
                                </button>
                                </a>
                                
                                </div>
                                    </div>


                                <div className="triangle">
                                
                                    </div>
                            

                    </div>
                            </div>
                   )
                })
            }
        </div>
    )
}

export default Product;