import React from "react";
import { Drawer, IconButton } from "@mui/material";
import "./modalpopup.scss";
import dataEngineeringImg from "../../assets/img/accolades/image4.png";
import genai from "../../assets/img/accolades/image1.png";
import dataScientist from "../../assets/img/accolades/image2.png";

import { Close } from "@mui/icons-material";

const ModalPopup = ({ setShowPopup, closeDiv, showPopup }) => {
  return (
    <>
      <Drawer
        sx={{
          backgroundColor: "tranparent",
          zIndex: "9999",
        }}
        transitionDuration={{ enter: 5000, exit: 300 }}
        open={showPopup}
        anchor="right"
        onClose={() => {
          setShowPopup(false);
          closeDiv();
        }}
        className="drawer-main"
      >
        <div className="main">
          {/* first blog pop up */}
          <div className="popup-box">
            <span className="image">
              <img src={dataEngineeringImg} alt="AIM" />
            </span>
            <span className="content">
              Recognised as a{" "}
              <span className="content1">Leader in Top Data Engineering</span>{" "}
              Service Providers
            </span>
            <div className="button-div">
              <a
                href="/aim-ranks-aays-a-leader-in-data-engineering-service-providers"
                target="_blank"
              >
                <button
                  onClick={() => closeDiv()}
                  style={{ background: "#bb1ccc", color: "white" }}
                >
                  Read More
                </button>
              </a>
            </div>
          </div>

          {/* // second blog popup */}
          <div className="popup-box margin-cls ">
            <span className="image">
              <img src={genai} alt="AIM" />
            </span>
            <span className="content">
              Recognised as a{" "}
              <span className="content1">Seasoned Vendor in Top Gen AI</span>{" "}
              Service Providers
            </span>
            <div className="button-div">
              <a href="/gen-ai-seasoned-vendor-aim-research" target="_blank">
                <button
                  onClick={() => closeDiv()}
                  style={{ background: "#bb1ccc", color: "white" }}
                >
                  Read More
                </button>
              </a>
            </div>
          </div>

          {/* // third blog popup */}
          <div className="popup-box margin-cls ">
            <span className="image">
              <img
                src={dataScientist}
                style={{
                  height: "150px",
                }}
                alt="AIM"
              />
            </span>
            <span className="content">
              Recognised as a{" "}
              <span className="content1">
                Workspace Leaders in the List of 50 Best Firms for Data
                Scientists
              </span>{" "}
              to Work for
            </span>
            <div className="button-div">
              <a href="/50-best-firms-for-data-scientists-2024" target="_blank">
                <button
                  onClick={() => closeDiv()}
                  style={{ background: "#bb1ccc", color: "white" }}
                >
                  Read More
                </button>
              </a>
            </div>
          </div>

          {/* // bottom two popups */}
          <div className="popup-container margin-cls">
            <div className="pop-up-box1">
              <div className="heading">Aadi</div>
              <div className="content">
                A Decision Intelligence <br />
                Co-Pilot for Finance function
              </div>

              <div className="button-div">
                <a href="/aays-decision-intelligence-aadi" target="_blank">
                  <button
                    onClick={() => closeDiv()}
                    style={{ background: "#bb1ccc", color: "white" }}
                  >
                    Read More
                  </button>
                </a>
              </div>
            </div>
            <div className="pop-up-box1">
              <div className="heading">Customer Success Stories</div>
              <div className="content">
                Learn how we democratise data and AI adoption
              </div>
              <div className="button-div">
                <a href="/customer-success-stories" target="_blank">
                  <button
                    onClick={() => closeDiv()}
                    style={{ background: "#bb1ccc", color: "white" }}
                  >
                    Read More
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="close-icon">
            <IconButton
              className="iconButton"
              sx={{
                backgroundColor: "#bb1ccc",
              }}
              onClick={() => closeDiv()}
            >
              <Close
                sx={{
                  color: "white",
                  fontSize: "15px",
                }}
              />
            </IconButton>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ModalPopup;
