import React from 'react'

function MiddleBanner(props) {
  return (
    <div className="section2">
      <div className="secondpage_section2 section2Text  ">
        <span className={props.class} >
          {props.text}
        </span>
      </div>
    </div>
  );
}

export default MiddleBanner