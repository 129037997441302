import React from "react";
import { useMediaQuery, useTheme } from "@mui/material/";
import { Typography } from "@mui/material";
import Section4 from "./Section4";
import forbes from "../../assets/img/forbes.png";
import leaderShipLogo1 from "../../assets/img/AIM-logo-black-1.png";
import HomePageSection4 from "../HomePageLayout/HomePageSection4";
import Sectionnew from "./Sectionnew";

const thirdSectionData = [
  {
    imageUrl: leaderShipLogo1,
    linkUrl:
      "https://councils.forbes.com/profile/Anshuman-Bhar-CEO-Aays/163d5fb9-6f86-4ab8-8d9a-e8de61296fe1",
    label: "Recognized as a Seasoned Vendor for Gen AI",
    description: "Gen AI Penetration and Maturity 2023 by AIM Research",
  },
  {
    imageUrl: leaderShipLogo1,
    linkUrl:
      "https://analyticsindiamag.com/top-data-science-providers-in-india-2022-penetration-and-maturity-pema-quadrant/",
    label: "Ranked amongst Top 5 Fastest Growing Data Science Firms",
    description: "Data Science Penetration and Maturity Quadrant 2022 by AIM",
  },
  {
    imageUrl: forbes,
    linkUrl:
      "https://councils.forbes.com/profile/Dwarika-Patro-Founder-Aays/b37ffba8-18bf-4728-a1a2-9eeb18d238f2",
    label: "Forbes Technology Council",
    description: "Member & Contributor",
  },
  {
    imageUrl: forbes,
    linkUrl:
      "https://councils.forbes.com/profile/Anshuman-Bhar-CEO-Aays/163d5fb9-6f86-4ab8-8d9a-e8de61296fe1",
    label: "Forbes Business Council",
    description: "Member & Contributor",
  },
  {
    imageUrl: leaderShipLogo1,
    linkUrl:
      "https://analyticsindiamag.com/aays-analytics-is-certified-as-a-best-firm-for-data-scientists/",
    label: "Certified Best Firm for Data Scientists (2023-24)",
    description: "By Analytics India Magazine",
  },
];

const fourthSectionContent = [
  "Unraveling complexities in commercial, finance, supply chain, and operations for Fortune 1000 and rapidly expanding global firms is our expertise",
  "Excelling in blending deep functional insight, advanced technology, and consulting acumen, we deliver rapid outcomes within intricate organizational structures",
  "Embracing personalized insights and solutions, we provide unparalleled functional context to data science solutions – this defines our approach",
];

const heading =
  "Our Solutions Powering Data and AI Navigation Across Industries";
const subheading =
  "Our AI and analytics solutions span diverse industries and functions, offering a vast array of capabilities to empower your success. We're your guide through toughest challenges, ensuring confidence in every step.";

function Section3() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="about-section3 padding-both">
      <div
        className="homepage-mobile"
        style={!isMobile ? { paddingBottom: "4rem" } : {}}
      >
        <HomePageSection4 heading={heading} subheading={subheading} />
      </div>

      <div className="second-section">
        <Section4 />
      </div>

      <div className="fourth-section">
        <div className="header-text">Why Partner with Aays?</div>

        <div className="fourth-section-grid">
          {fourthSectionContent.map((content, index) => (
            <div key={index} className="fourth-section-item-box">
              <span className="item-content">{content}</span>
            </div>
          ))}
        </div>
        <div> <Sectionnew/> </div>
        
      </div>

       {/* <div className="third-section">
        {thirdSectionData.map((element, index) => (
          <div className="third-section-box" key={index}>
            {!isMobile && (
              <div className="image-div">
                <img
                  loading="lazy"
                  style={{ height: "30px", width: "60px" }}
                  src={element.imageUrl}
                  alt="AIM"
                />
              </div>
            )}

            <div className="content-box">
              <Typography className="font-family">
                <a style={{ fontSize: "16px" }} href={element.linkUrl}>
                  <b>{element.label}</b>
                </a>
              </Typography>

              <Typography variant="body1" className="font-family">
                <i>{element.description}</i>
              </Typography>
          </div>
          </div>
        ))}
      </div> */}
  
    </div>
  );
}

export default Section3;
