import React, { useState } from "react";
import CustomerSectionBox1 from "../../reuseLayout/CustomerSectionBox1";
import ai1 from "../../assets/img/Aays_Gen AI Use Cases.square.jpg";
import ai2 from "../../assets/img/Aays_Gen AI Investment Strategies.Square.jpg";
import ai2as from "../../assets/img/AIML-decision-making_Aays.jpg";
import ai4 from "../../assets/img/ai4.jpg"

const AiAndGenerativeAI = () => {
  const [limit, setLimit] = useState(2);

  const items = [
   
     {
      title: "Leveraging AI and ML for Business Decision-Making?",
      picture: ai2as,
       date: "September 4, 2024  ",
       read: "2 min read",
      redirectURL: "/blogs/ai-ml-for-business-decision-making",
    },
    {
      title: "Gen AI Priority Quadrant for Enterprises",
      picture: ai1,
      date: "Jul 1, 2024 ",
      read: "2 min read",
      redirectURL: "/blogs/prioritizing-ai-and-gen-ai-use-cases",
    },
    {
      title:
        "Why Invest in Generative AI Now? Exploring AI Investment Strategies",
      picture: ai2,
      date: "Jul 8, 2024 ",
      read: "2 min read",
      redirectURL: "/blogs/why-invest-in-gen-ai-now",
    },
    {
      title: "How is big data and analytics helping the automotive industry?",
      picture: ai4,
      date: "Feb 16, 2023",
      read: "2 min read",
      redirectURL : '/blogs/How-is-big-data-and-analytics-helping-the-automotive-industry'

    },
    // {
    //   title: "How is big data and analytics helping the automotive industry?",
    //   picture: ai4,
    //   date: "Feb 16, 2023",
    //   read: "2 min read",
    //   redirectURL:
    //     "/blogs/How-is-big-data-and-analytics-helping-the-automotive-industry",
    // },
  ];

  const visibleItems = items.slice(0, limit);

  const handleSeeMoreClick = () => {
    setLimit((prevLimit) => prevLimit + 2);
  };

  const handleSeeLessClick = () => {
    setLimit(2);
  };

  return (
    <div>
      <div
        className="success-story-page-section-3 scroll-section"
        id="aiAndGenerativeAi"
      >
        <div className="success-story-page-section-3-margin">
          <div className="success-story-page-section-3-Header-text">
            AI and Generative AI
          </div>
          <div className="success-story-page-section-3-grid">
            {visibleItems.map((item, index) => (
              <CustomerSectionBox1
                key={index}
                title={item.title}
                picture={item.picture}
                date={item.date}
                read={item.read}
                redirectURL={item.redirectURL}
                class="success-story-page-section-3-grid-items-texta"
              />
            ))}
          </div>

          {limit < items.length ? (
            <div className="seeMore" onClick={handleSeeMoreClick}>
              See More
            </div>
          ) : (
           // <></>
             <div className="seeMore" onClick={handleSeeLessClick}>
               See Less
             </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AiAndGenerativeAI;
