import React from "react";
import NewsRoomReuse from "./NewsRoomReuse";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1325 },
    items: 4,
  },
  superdesktop: {
    breakpoint: { max: 1325, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};

function Section4() {
  return (
    <div id="caseStudySection4" className="blog-success-section2">
      <div className="success-story-page-section-2-Header-text  Blogs-Thought">
        Blogs & Thought Leadership
      </div>
      <div className="customer-section2-body margin-box">
        <div className="section2-box">
          <Carousel
            className="customer-success-main"
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            infinite={true}
            slidesToSlide={1}
            swipeable={true}
            draggable={false}
            showDots={false}
          >
            <NewsRoomReuse
              heading="DATAQUEST"
              title="Data analytics is transforming the way CPG companies make pricing decisions"
              content="Unlock profitable avenues with data analytics for CPG pricing optimization. Gain insights into customer behavior, demand patterns, and market trends. Drive revenue growth and foster customer loyalty through optimized pricing strategies.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://www.dqindia.com/how-data-analytics-is-transforming-the-way-cpg-companies-make-pricing-decisions/"
            />
            <NewsRoomReuse
              heading="DATAQUEST"
              title="How is analytics capable of making the supply chain more reliable?"
              content="By collecting, analysing, and simplifying supply chain data, businesses can significantly improve their forecasting accuracy, route scheduling, and their decision-making capabilities.  
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://www.dqindia.com/how-is-analytics-capable-of-making-the-supply-chain-more-reliable/"
            />
            <NewsRoomReuse
              heading="ETCIO"
              title="Reducing Supply Chain Costs in CPG Firms Through Data-Driven Insights"
              content="By investing in data-driven insights, CPG companies can reduce costs and clear out inefficiencies associated with their supply chain networks, leading to enhanced profitability across the value chain.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://cio.economictimes.indiatimes.com/news/big-data/avinash-sidha-aays-analytics-on-reducing-supply-chain-costs-in-cpg-firms-through-data-driven-insights/99975669"
            />

            <NewsRoomReuse
              heading="MANUFACTURING TODAY INDIA"
              title="How is big data and analytics helping the automotive industry?"
              content="Here's a look at some brand-new degrees of opportunities in the automotive industry, arising from the adoption of big data and analytics. These include improved efficiency, elevated customer experiences, new levels of growth and profitability, and much more.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://www.manufacturingtodayindia.com/sectors/how-is-big-data-and-analytics-helping-the-automotive-industry"
            />

            <NewsRoomReuse
              heading="CIO & LEADER"
              title="Top 5 Trends of Data Analytics in 2023"
              content="The world of analytics and data is always evolving. Technologies that provide quicker and more accurate access to information are continually being developed. Our Principal Data Architect, Ajith Sasidharan, provides a rundown of the key trends that will have the biggest impact on how data and analytics are used to fuel business success in 2023.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://www.cioandleader.com/article/2022/12/29/top-5-trends-data-analytics-2023"
            />

            <NewsRoomReuse
              heading="EXPRESS COMPUTER"
              title="Five tips to transform your organisation's analytics journey"
              content="Anshuman Bhar talks about the holistic transformation in the organization's analytics journey to realize the maximum benefits from data.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://www.expresscomputer.in/guest-blogs/five-tips-to-transform-your-organisations-analytics-journey/89648/"
            />

            <NewsRoomReuse
              heading="TECHIEXPERT"
              title="Implementing Data Analytics in Business and Finance"
              content="Businesses frequently struggle to manage the massive amount of data. Despite the fact that data analytics can have a significant impact on a company's bottom line, it can be challenging to know where to start. Here's a detailed guide to integrating data analytics in your business and finance function.
            "
              minHeight="389px"
              color="#212121"
              redirectURL="https://www.techiexpert.com/data-analytics-implementation-journey-in-business-and-finance/"
            />

            <NewsRoomReuse
              heading="CXO TODAY"
              title="Transforming Finance Function with Advanced Analytics"
              content="The use of advanced analytics in finance helps CFOs keep their organizations agile and adopt a forward-looking approach for enhanced profitability and enriched customer experiences. In this article, Anil Kumar shares a success manual on things finance executives should know to build, strengthen and lead the finance transformations.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://cxotoday.com/corner-office/transforming-finance-function-with-advanced-analytics-what-cfos-need-to-know/"
            />

            <NewsRoomReuse
              heading="ETCIO"
              title="How large enterprises are democratizing data science, ML in the corporate finance space"
              content="Now, with finance functions moving from BI to AI, the finance leaders are realizing that the skills of data scientists have to become an essential part of their toolset. They desire to be able to apply advanced analytics methods to gain deeper insights.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://cio.economictimes.indiatimes.com/news/business-analytics/anil-kumar-aays-analytics-on-how-large-enterprises-are-democratising-data-science-ml-in-the-corporate-finance-space/96214109"
            />

            <NewsRoomReuse
              heading="CIOL"
              title="How data analytics can significantly help manage working capital?"
              content="A crucial step in increasing working capital effectiveness is to use data analytics. We provide financial activities a data-driven approach, and help businesses transform how they efficiently manage their working capital and cash flows.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://www.ciol.com/data-analytics-can-significantly-help-manage-working-capital/"
            />

            <NewsRoomReuse
              heading="TIMES OF INDIA"
              title="How advanced analytics and machine learning are transforming the role of Finance Controllers"
              content="Learn why it is becoming crucial for businesses to empower their finance controllers with advanced analytics and AI/ML led solutions.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://timesofindia.indiatimes.com/blogs/voices/how-advanced-analytics-and-machine-learning-are-transforming-the-role-of-finance-controllers/"
            />

            <NewsRoomReuse
              heading="INDIA AI "
              title="How is AI transforming FP&A in the consumer goods industry?"
              content="Keeping up with the rapidly evolving CG industry can be a difficult task for traditional FP&A approaches, and this can make it challenging for finance teams to make informed decisions. To gain a competitive advantage, many CG conglomerates are adopting AI to transform their FP&A processes.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://indiaai.gov.in/article/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry"
            />

            <NewsRoomReuse
              heading="CXO TODAY"
              title="Top companies in the financial analytics space"
              content="We got featured in the list of top analytics contenders in the finance analytics space, giving us a validation that we are one of the best when it comes to democratising data science and ML in the corporate finance space.
            "
              color="#212121"
              minHeight="389px"
              redirectURL="https://cxotoday.com/cxo-bytes/top-companies-in-the-financial-analytics-space/"
            />
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Section4;
