import image1 from "../../assets/img/accolades/FT_APac_FGC2024_Logo_CMYK_White.png";
import image2 from "../../assets/img/accolades/image2.png";
import image3 from "../../assets/img/accolades/image4.png";
import image4 from "../../assets/img/accolades/microsoft_solution.png";

import image6 from "../../assets/img/accolades/image6.png";
import image7 from "../../assets/img/accolades/image1.png"
import newimage12 from "../../assets/img/accolades/newimage12.png"
import seasonvendor from "../../assets/img/accolades/seson-vender.png"


export const AccoladesData = [
  {
    id: 1,
    heading: "Ranked 31st in 500 High Growth Companies",
    image: image1,
  },
  {
    id: 2,
    heading: "Ranked 13th in 50 Best Firms for Data Scientists",
    image: image2,
  },
  {
    id: 3,
    heading: "Recognized as Leaders in Top Data Engineering Service Providers",
    image: image3,
  },
  {
    id: 4,
    heading:
      "Cloud Partners with Microsoft in Azure Data & AI and Azure Platform",
    image: image4,
  },
  {
    id: 6,
    heading:  "Recognised as Seasoned Vendor in Top Data Science Service Providers 2024",
    image: seasonvendor,
  },
  
  {
    id: 5,
    heading: "Certified as a Great Place to Work",
    image: newimage12,
  },
  {
    id: 7,
    heading: "Recognised as Seasoned Vendors in Top Gen AI Service Providers",
    image: image7,
  },
  {
    id: 8,
    heading:  "Certified as Best Firm for Data Scientists by AIM",
    image: image6,
  },
];

