import React from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/img/Arrow 1.svg';

function LeftArrow({ startIndex = '', setStartIndex = () => {} }) {
    const handleLeft = () => {
        setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const disablePointerEvents = startIndex === 0;
    return (
        <div style={{
            display: 'flex', 
            alignItems: 'center',
            cursor: disablePointerEvents ? 'not-allowed' : 'pointer',
            pointerEvents: disablePointerEvents ? 'none' : 'auto',
            opacity: disablePointerEvents ? 0.5 : 1
        }} 
        onClick={handleLeft}
        >
            <ArrowIcon width="100px" style={{transform: 'rotate(180deg)'}}/>
        </div>
    );
}

export default LeftArrow;
