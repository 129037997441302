import React,{useEffect} from "react";
import { useMediaQuery } from "@mui/material";
import FinancePage from "../pageLayout/FinanceLayout/FinancePage";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import { Helmet,HelmetProvider } from "react-helmet-async";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import mobileBannerImage from "../assets/img/finance-banner-right.png"
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";


const nextstep = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y + (90 * $(window).height()) / 100);
};

const nextstepDown = () => {
  var y = $(window).scrollTop(); //your current y position on the page
  $(window).scrollTop(y - (90 * $(window).height()) / 100);
};

const updateMetaTags = () => {
  document.title = 'Finance Analytics and AI Solutions | Aays';

  const keywords = document.querySelector('meta[name="keywords"]');
  if (keywords) {
    keywords.setAttribute('content', 'Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics');
  } else {
    const newKeywords = document.createElement('meta');
    newKeywords.name = 'keywords';
    newKeywords.content = 'Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics';
    document.head.appendChild(newKeywords);
  }

  const description = document.querySelector('meta[name="description"]');
  if (description) {
    description.setAttribute('content', 'AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools.');
  } else {
    const newDescription = document.createElement('meta');
    newDescription.name = 'description';
    newDescription.content = 'AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools.';
    document.head.appendChild(newDescription);
  }

  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content', 'Finance Analytics and AI Solutions | Aays');
  } else {
    const newOgTitle = document.createElement('meta');
    newOgTitle.setAttribute('property', 'og:title');
    newOgTitle.content = 'Finance Analytics and AI Solutions | Aays';
    document.head.appendChild(newOgTitle);
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute('content', 'https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png');
  } else {
    const newOgImage = document.createElement('meta');
    newOgImage.setAttribute('property', 'og:image');
    newOgImage.content = 'https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png';
    document.head.appendChild(newOgImage);
  }
};



function Finance() {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  useEffect(() => {
    updateMetaTags()

  });

  return (
    <HelmetProvider>
    <div id="homePage" className="Finance-main">
      <GoogleAnalytics 
      // description="I-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools."
      // keywords = "Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics"
      // title ="Finance Analytics and AI Solutions | Aays "
      />

      <Helmet>
        <meta
          name="keywords"
          content="Aays, Aays Analytics, Aays Finance analytics Solutions, AI in finance, AI and analytics for finance, AI in Corporate Finance, Finance Analytics, Corporate Finance AI Solutions, AI tools for Finance, FP&A analytics"
        />

      <meta name="description" content="AI-driven solutions for corporate finance. Empower your controllers, FP&A teams, and CFOs with advanced finance analytics and AI tools." /> 
      <meta property='og:title' content="Finance Analytics and AI Solutions | Aays" />
      <meta property='og:image' content='https://aaysadvisoryprod.s3.us-east-2.amazonaws.com/img/SEO_Aays_Cash%20Analytics.png' />
      </Helmet>

      {isMobile ? (
        <NewHeaderSectionMobile
          text1="Revolutionising the Finance Function"
          text2="through an AI-led Approach"
          subtext1="Cutting-edge Commercial"
          subtext2="and Finance Solutions We Offer"
          picture={mobileBannerImage}
        />
      ) : isIpad ? (
        <IpadHeaderSection
          text1="Revolutionising the Finance Function"
          text2="through an AI-led Approach"
          subtext1="Cutting-edge Commercial"
          subtext2="and Finance Solutions We Offer"
          picture={mobileBannerImage}
        />
      ) : (
        <NewHeaderSection
          text1="Revolutionising the Finance Function"
          text2="through an AI-led Approach"
          subtext1="Cutting-edge Commercial"
          subtext2="and Finance Solutions We Offer"
          picture={mobileBannerImage}
        />
      )}

      <MiddleBanner text="Contextualising analytics journey with deep functional expertise" />
      <FinancePage />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
    </HelmetProvider>
  );
}

export default Finance;
