import React from "react";
import supplyChain from "../../assets/img/supply-chain-white-new-icon.svg";
import finance from "../../assets/img/finance-white-new-icon.svg";
import marketing from "../../assets/img/operations-white-new-icon.svg";
import $ from "jquery";

const data = [
  {
    id: 1,
    urlId: "#section2-customer-success-story",
    title: "Supply Chain",
    image: supplyChain,
  },
  {
    id: 2,
    urlId: "#caseStudySection3",
    title: "Finance",
    image: finance,
  },
  {
    id: 3,
    urlId: "#caseStudySection4",
    title: "Operations",
    image: marketing,
  },
];

$(document).ready(function () {
  $("a").on("click", function (e) {
    // e.preventDefault();

    var target = this.hash,
      $target = $(target);

    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $target.offset().top - 80,
        },
        0,
        "swing",
        function () {}
      );

    return false;
  });
});

function Section1() {
  return (
    <div className="customer-section1-main">
      <div className="customer-section1-body">
        <div className="success-stories-section2-new-css">
          {data.map((item) => (
            <a href={item.urlId}>
              <div className="icon-box">
                <span className="image-div">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="feature-icon"
                  />
                </span>
                <span className="text">{item.title}</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section1;
