import React from "react";
import Group from "../../assets/img/Billionimages/Group.png";
import Groupa from "../../assets/img/Billionimages/Groupa.png";
import Groupb from "../../assets/img/Billionimages/Groupb.png";
import Groupc from "../../assets/img/Billionimages/Groupc.png";
import Groupd from "../../assets/img/Billionimages/Groupd.png";
import Groupe from "../../assets/img/Billionimages/Groupe.png";
import { useMediaQuery } from "@mui/material/";


function HomePageSection2() {
  const isMobile = useMediaQuery("(max-width: 920px)");
  return (
    
    <div>
      
    {!isMobile && (
      <div className="section2-homepage-main">
         
        
        <div className="homepage-body homepage-sections-padding">
          <div className="data-drive-succ">
        <p >Data Driven Success </p> </div>
        <div className="delivered-buisness" > 
          <p>Delivered business impact of $2Bn using AI and data led solutions</p>
        </div>
        
       
        
          <div className="mainContainer">
            <div className="topContainer">
              <div className="box">
              <div className="imagess" style={{height:'130px',width:'100px',background:'#191919', display: 'flex', justifyContent: 'center', alignItems: 'center',marginRight:'20px',}}>
                  <img loading="lazy" src={Group} alt="sytem" style={{height:'62',width:'62',}}/>
                </div>
                <div style={{paddingTop:'21px'}}>
                  <h2 className="counting-number">
                    {/* {counterOn && (
                  <CountUp start={0} end={100} duration={2} delay={0} />
                )} */}
                    $200M
                  </h2>
                  <span className="text">
                  Supply Chain  <br /> Optimization
                  </span>
                </div>
              </div>

              <div className="box box-border">
                <div className="imagess" style={{height:'130px',width:'100px',background:'#191919', display: 'flex', justifyContent: 'center', alignItems: 'center',marginRight:'20px'}}>
                  <img loading="lazy" src={Groupb} alt="system" style={{maxHeight:'62',maxWidth:'62',}}/>
                </div>
                <div style={{paddingTop:'21px'}}>
                  <h2 className="counting-number">$500M</h2>
                  <span className="text">
                  Working Capital <br /> Optimization
                  </span>
                </div>
              </div>

              <div className="box">
              <div className="imagess" style={{height:'130px',width:'100px',background:'#191919', display: 'flex', justifyContent: 'center', alignItems: 'center',marginRight:'20px'}}>
                  <img loading="lazy" src={Groupa} alt="systems" style={{maxHeight:'62',maxWidth:'62',}}/>
                </div>
                <div style={{paddingTop:'21px'}}>
                  <h2 className="counting-number">$400M</h2>
                  <span className="text">
                    {" "}
                    Revenue Leakage <br /> Visibility
                  </span>
                </div>
              </div>
            </div>

            {/* // bottom container  */}
            <div className="bottomContainer">
              <div className="box">
              <div className="imagess" style={{height:'130px',width:'100px',background:'#191919', display: 'flex', justifyContent: 'center', alignItems: 'center',marginRight:'20px'}}>
                  <img loading="lazy" src={Groupc} alt="systema" style={{maxHeight:'62',maxWidth:'62'}}/>
                </div>
                <div style={{paddingTop:'21px'}}>
                  <h2 className="counting-number">
                    {/* {counterOn && (
                  <CountUp start={0} end={100} duration={2} delay={0} />
                )} */}
                    $300M
                  </h2>
                  <span className="text">
                  Profitability <br /> Improvement

                  </span>
                </div>
              </div>

              <div className="box box-border">
              <div className="imagess" style={{height:'130px',width:'100px',background:'#191919', display: 'flex', justifyContent: 'center', alignItems: 'center',marginRight:'20px'}}>
                  <img loading="lazy" src={Groupe} alt="systemb" style={{maxHeight:'62',maxWidth:'62'}}/>
                </div>
                <div style={{paddingTop:'21px'}}>
                  <h2 className="counting-number">$600M</h2>
                  <span className="text">
                  ESG  <br/> Initiatives &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                  </span>
                </div>
              </div>

              <div className="box">
              <div className="imagess" style={{height:'130px',width:'100px',background:'#191919', display: 'flex', justifyContent: 'center', alignItems: 'center',marginRight:'20px'}}>
                  <img loading="lazy" src={Groupd} alt="systemd" style={{maxHeight:'62',maxWidth:'62' }}/>
                </div>
              
                <div style={{paddingTop:'21px'}}>
                  <h2 className="counting-number">$450M</h2>
                  <span className="text">
                    {" "}
                    Network  &nbsp; &nbsp; &nbsp;&nbsp; <br/> Optimization 
                  </span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      
      {isMobile && (
          <div className="section2-homepage-main">
            
        
          <div className="homepage-body homepage-sections-padding">
          <div className="data-drive-succ">
        <p >Data Driven Success </p> </div>
        <div className="delivered-buisness" > 
          <p>Delivered business impact of $2Bn using AI and data led solutions</p>
        </div>
            <div className="mainContainer">
              <div className="topContainer">
                <div className="box">
                
                  <div style={{paddingTop:'21px'}}>
                    <h2 className="counting-number">
                      {/* {counterOn && (
                    <CountUp start={0} end={100} duration={2} delay={0} />
                  )} */}
                      $200M
                    </h2>
                    <span className="text">
                    Supply Chain  <br /> Optimization
                    </span>
                  </div>
                </div>
  
                <div className="box box-border">
                
                  <div style={{paddingTop:'21px'}}>
                    <h2 className="counting-number">$500M</h2>
                    <span className="text">
                    Working Capital <br /> Optimization
                    </span>
                  </div>
                </div>
  
                <div className="box">
                
                  <div style={{paddingTop:'21px'}}>
                    <h2 className="counting-number">$400M</h2>
                    <span className="text">
                      {" "}
                      Revenue Leakage <br /> Visibility
                    </span>
                  </div>
                </div>
              </div>
  
              {/* // bottom container  */}
              <div className="bottomContainer">
                <div className="box">
               
                  <div style={{paddingTop:'21px'}}>
                    <h2 className="counting-number">
                      {/* {counterOn && (
                    <CountUp start={0} end={100} duration={2} delay={0} />
                  )} */}
                      $300M
                    </h2>
                    <span className="text">
                    Profitability <br /> Improvement
  
                    </span>
                  </div>
                </div>
  
                <div className="box box-border">
                
                  <div style={{paddingTop:'21px'}}>
                    <h2 className="counting-number">$600M</h2>
                    <span className="text">
                    ESG  <br/> Initiatives &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                    </span>
                  </div>
                </div>
  
                <div className="box">
               
                
                  <div style={{paddingTop:'21px'}}>
                    <h2 className="counting-number">$450M</h2>
                    <span className="text">
                      {" "}
                      Network  &nbsp; &nbsp; &nbsp;&nbsp; <br/> Optimization 
                    </span>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
         
       ) }
    </div>
  
  
  );
}

export default HomePageSection2;
