import React,{useEffect} from "react";
import Indicusstomersuccessstories from "../../pageLayout/CustomerSuccessStoryMoreLayout/Indicusstomersuccessstories";
import Footer from "../../nav/Footer";
// import Resources from "../../reuseLayout/Resources";
import picture from "../../assets/img/DailySalesPositioning-case-study.jpg"
import { Helmet } from "react-helmet";
const storyData = {
  title:
    "Normalising daily sales position leveraging advanced smoothening and forecasting techniques",
  content:
    "For CPG companies, accurate reporting of daily business position is crucial, but often challenging. This case study reveals how utilizing data analytics revolutionized the way a multi-billion-dollar global consumer goods company tackled this issue. By implementing advanced smoothening and forecasting techniques, they achieved accurate daily sales reporting, informed decision-making, improved trust in data, better governance over trade expenditures, optimized spending, enhanced visibility on seasonal changes, and proactive adaptation to shifting demand.",
  subTitle1: "Accurate reporting",
  subText1: "of Daily Business Position",
  subTitle2: "Better governance and control",
  subText2: "on Trade Expenditures",
  imageData: picture
};

function Daily_Sales_Positioning() {



  useEffect(() => {
    document.title = "Advanced Daily Sales Reporting Techniques | CPG Case Study";
    const metaTags = document.getElementsByTagName("META");
    if (metaTags.length > 3) {
      metaTags[3].content = "Advanced smoothening and forecasting techniques revolutionized daily sales reporting for a global CPG. Witness improved data trust, governance over trade expenditures.";
    }
  });

  return (
    <div className="backround-black">
      <Helmet>
        <meta
          name="keywords"
          content="Daily sales reporting, forecasting techniques, trade expenditures governance, spending optimization, seasonal changes visibility"
        />
      </Helmet>

      <div>
        <Indicusstomersuccessstories data={storyData} />
      </div>

      {/* <Resources /> */}
      <Footer />
    </div>
  );
}

export default Daily_Sales_Positioning;
