import React from "react";
import Instagram from "../../assets/img/instagram-icon.png";
import LinkedIn from "../../assets/img/linkedin-icon.png";
import Celebration from "../../assets/img/5year-celebration.png";
import DataScientistAward from "../../assets/img/data-scientist-award.png";
import AiPoweredSuccess from "../../assets/img/ai-powered-success.png";
import AaysHackathon from "../../assets/img/aays-hackathon.png";
import { useMediaQuery } from "@mui/material";

const imageData = [
  {
    id: 1,
    image: Celebration,
    class: "main-image",
  },
  {
    id: 2,
    image: DataScientistAward,
    class: "main-image1",
  },
  {
    id: 3,
    image: AiPoweredSuccess,
    class: "main-image2",
  },
  {
    id: 4,
    image: AaysHackathon,
    class: "main-image3",
  },
];

const slidesData = [
  {
    id: 1,
    containerClass: "container1",
    heading: "5 Years Celebration",
    content:
      "There were celebrations all around when we hit the monumental milestone - 5 years!",
    tag: "#5YearsStrong",
  },

  {
    id: 2,
    containerClass: "container2",
    heading: "Best Firm for Data Scientists Award",
    content:
      "We were honored to receive this award from Jonty Rhodes at Cypher2023! ",
    tag: "#BestFirmForDataScientists",
  },
  {
    id: 3,
    containerClass: "container3",
    heading: "AI-Powered Success: Empowering SMEs",
    content:
      "We participated in SMEACCelerate Forum 2023 at the Google campus.",
    tag: "#AmChamSingapore",
  },
  {
    id: 4,
    containerClass: "container4",
    heading: "Aays Hackathon 2023",
    content:
      "The Hackathon was a resounding success, filled with innovation, and excitement!",
    tag: "#Innovation",
  },
];
const mobileSlidesData = [
  {
    id: 1,
    containerClass: "mobile-container1",
    heading: "5 Years Celebration",
    content:
      "There were celebrations all around when we hit the monumental milestone - 5 years!",
    tag: "#5YearsStrong",
  },

  {
    id: 2,
    containerClass: "mobile-container2",
    heading: "Best Firm for Data Scientists Award",
    content:
      "We were honored to receive this award from Jonty Rhodes at Cypher2023! ",
    tag: "#BestFirmForDataScientists",
  },
  {
    id: 3,
    containerClass: "mobile-container3",
    heading: "AI-Powered Success: Empowering SMEs",
    content:
      "We participated in SMEACCelerate Forum 2023 at the Google campus.",
    tag: "#AmChamSingapore",
  },
  {
    id: 4,
    containerClass: "mobile-container4",
    heading: "Aays Hackathon 2023",
    content:
      "The Hackathon was a resounding success, filled with innovation, and excitement!",
    tag: "#Innovation",
  },
];

function Section6() {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <div className="career-section6-new">
      <div className="main">
        {isMobile ? (
          <div className="grid-container">
            <div className="image-box">
              {imageData.map((item) => (
                <img
                  loading="lazy"
                  key={item.id}
                  className={item.class}
                  src={item.image}
                  alt="celebration"
                />
              ))}
            </div>
            <div className="main-container">
              {mobileSlidesData.map((item) => (
                <div className={item.containerClass}>
                  <div className="heading"> {item.heading}</div>
                  <div className="content">{item.content}</div>
                  <div className="tags">{item.tag}</div>
                </div>
              ))}

              <div className="social-icons">
                <a href="https://www.linkedin.com/company/aays-analytics/">
                  <img loading="lazy" src={LinkedIn} alt="linkedin" />
                </a>
                <img loading="lazy" src={Instagram} alt="instagram" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* {slidesData.map((item) => ( */}
            <div className="grid-container">
              <div className="item1">
                <div className="social-icons">
                  <a href="https://www.linkedin.com/company/aays-analytics/">
                    <img loading="lazy" src={LinkedIn} alt="Linkedin" />
                  </a>
                  <img loading="lazy" src={Instagram} alt="Instagram" />
                </div>

                {imageData.map((item) => (
                  <img
                    loading="lazy"
                    key={item.id}
                    className={item.class}
                    src={item.image}
                    alt="celebration"
                  />
                ))}
              </div>

              <div className="item2">
                {slidesData.map((item) => (
                  <div className={item.containerClass}>
                    <div className="heading">{item.heading}</div>
                    <div className="content">{item.content}</div>
                    <div className="tags">{item.tag}</div>
                  </div>
                ))}

                <div className="container1"></div>
              </div>
            </div>
            {/* // ))} */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Section6;
