import React, { useEffect } from "react";
import "../../pageLayout/BlogMoreLayout/GenAI/GenAI.scss";
import GenAiBanner from "../../assets/img/indi-blogs-page-uk-sigapore.jpg";
import Navbar from "../../nav/NavBar";
import Footer from "../../nav/Footer";
import $ from "jquery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "../../../app/GoogleAnalytics";
function AaysInSingapore() {
  const nextstep = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y + 700);
  };

  const nextstepDown = () => {
    var y = $(window).scrollTop(); //your current y position on the page
    $(window).scrollTop(y - 700);
  };

  useEffect(() => {
    document.title = "Aays Analytics in Singapore";
    document.getElementsByTagName("META")[3].content =
      "Aays Analytics is now in Singapore to accelerate the financial analytics transformation process for businesses looking to leverage the powers of data analytics";
  });
     
    const data = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "All Blogs",
        url: "/Blog",
      },
    ];


  return (
    <div id="homePage">
      <Helmet>
        <meta name="keywords" content="Aays analytics in APAC region" />
      </Helmet>

      <Navbar bg="black" />
      <GoogleAnalytics/>

      <div className="GenAi-body">
        <div className="margin-blogMore">
          <div className="GenAi-main">
          <div className="back-button">
                {data.map((ele, index) => (
                  <div className="back">
                    <a href={ele.url} className="content" key={index}>
                      {ele.text}
                    </a>
                    {index !== data.length - 1 && (
                      <div style={{ color: "black" }}>/</div>
                    )}
                  </div>
                ))}
              </div>
            <h1 className="main-heading">
              {" "}
              Aays Analytics forays into APAC region; kick-starts operations in
              Singapore
            </h1>

            <img
              className="blogImg"
              style={{ filter: "grayscale(1)" }}
              src={GenAiBanner}
              alt="blog1"
            />

            <div className="imgContent">
              <p className="heading-content">
                <b style={{ color: "#E67D1C" }}>
                  New Delhi, September 19, 2022
                </b>{" "}
                : Aays, a data analytics company, that provides financial
                analytics consulting and business intelligence services, starts
                its operations in Singapore as it looks to strengthen its
                foothold in the APAC region
              </p>

              <p className="heading-content">
                More number of businesses in the APAC region are looking to
                align their transformation roadmaps by leveraging the benefits
                of data analytics, and Aays aims to play a significant role in
                accelerating the finance analytics transformation journey for
                these organizations.
              </p>

              <p className="heading-content">
                The Singapore presence will help the company come closer to its
                current clients in the region, in addition to helping the
                business reach out to new customers in the Southeast Asian
                market. As an ongoing business strategy, Aays has been
                collaborating with major conglomerates and Fortune 1000
                businesses worldwide, particularly in the CPG and automotive
                sectors.
              </p>

              <p className="heading-content">
                It is also noteworthy to mention that this data analytics
                company has registered a growth of c.5X in terms of revenue in
                the fiscal year 2021-22.
              </p>

              <p className="heading-content">
                Commenting on the recent development,{" "}
                <b style={{ color: "#E67D1C" }}>
                  Anshuman Bhar, CEO & Co-founder at Aays Analytics
                </b>
                , said, "We are thrilled about our new presence in Singapore. We
                want to make sure we have a footprint where our clients and
                potential customers are. Singapore is a hub where many large
                international corporations have their regional headquarters.
                Therefore, this expansion would enable us to respond swiftly to
                the demands of the customers in that area."
              </p>

              <p className="heading-content">
                "By establishing our presence in Singapore, we aim to increase
                the company's reach in the SEA area with a hope to explore and
                create new business opportunities,” said{" "}
                <b style={{ color: "#E67D1C" }}>
                  Dwarika Patro, COO & Co-founder at Aays Analytics
                </b>
              </p>

              <p className="heading-content">
                The distinctive competence of Aays in creating a highly scalable
                and robust data analytics system has helped more than 50 clients
                all over the world, with its business impact reaching more than
                $2 Billion.
              </p>

              <p className="heading-content">
                Aays is a boutique AI & data consulting firm which specialises
                in democratising data science and ML in corporate analytics
                space. They partner with global conglomerates having large,
                complex data footprints and help them contextualise their data
                science journey with deep functional focus. Solutions are
                typically targeted at the CXO office, FP&A, controllers, and
                finance operations.
              </p>

              <p className="heading-content">
                Since their inception in 2018, they have solved some of the most
                complex business problems faced by fortune 1000 and fast-growing
                companies in the world utilising big data, cloud computing, and
                AI/ML. They are backed by a strong management team with IIT/IIM
                background and over a decade of industry experience with fortune
                100 companies.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div onClick={nextstep} className="scroll-btn">
        <button className="arrowBtn">
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>

      <div className="scroll-btn-down" onClick={nextstepDown}>
        <button className="arrowBtn">
          <KeyboardArrowUpIcon
            sx={{
              fontSize: "40px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default AaysInSingapore;
