import AccoladesBox from "./AccoladesBox";
import "./homeNewChanges.scss";
import { AccoladesData } from "./AccoladesData";
import { AccoladesDataCarousel1 } from "./AccoladesData";
import { AccoladesDataCarousel2 } from "./AccoladesData";
import { useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1296 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1296, min: 951 },
    items: 3,
  },
  supertablet: {
    breakpoint: { max: 951, min: 603 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 603, min: 0 },
    items: 1,
  },
};

const AccoladesAndRecognitions = ({className}) => {
  
  const theme = useTheme();

  
  return (
    <>
      <div id="accolades">
        <div className = {`${className}`}>
          <div className="heading-main">Accolades and Recognitions</div>


            <Carousel
              responsive={responsive}
              dotListClass="custom-dot-list-styles"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={1500}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              showDots={false}
            >
              {AccoladesData.map((data) => (
                <AccoladesBox
                  key={data.id}
                  title={data.heading}
                  description={data.description}
                  image={data.image}
                />
              ))}
            </Carousel>
          
        </div>
      </div>
    </>
  );
};

export default AccoladesAndRecognitions;
