import React from "react";
import "react-multi-carousel/lib/styles.css";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import blog1 from "../../assets/img/blog1.png";
import blog2 from "../../assets/img/blog2.jpg";
import blog3 from "../../assets/img/blog3.jpg";

const Section5 = ({ data, category }) => {
  const filteredData = data.filter((item) => item.category.includes(category));
  // console.log("filtered data", filteredData);
  return (
    <div id="section5-customer-success-story">
      <div className="success-story-page-section-5">
        <div className="success-story-page-section-5-margin">
          <div
            style={{
              position: "relative",
              zIndex: "1",
            }}
            className="success-story-page-section-5-Header-text"
          >
            Resources
          </div>
          <div className="success-story-page-section-5-grid">
            {filteredData.map((item) => (
              <CustomerSectionBox
                picture={item.picture}
                title1={item.title}
                date={item.date}
                read={item.read}
                class={item.class}
                redirectURL={item.redirectURL}
              />
            ))}

            {/* <CustomerSectionBox
              picture={blog1}
              title1="Data Analytics Implementation Journey in Business and Finance"
              date="March 31, 2023 "
              read="2 min read"
              class="content-box-section2a"
              redirectURL="/blogs/data-analytics-implementation-journey-in-business-and-finance"
            />
            <CustomerSectionBox
              picture={blog2}
              title1="How is AI transforming FP&A in the consumer goods industry?"
              date="March 3, 2023  "
              read="5 min read "
              class="content-box-section2a"
              redirectURL="/blogs/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry"
            />
            <CustomerSectionBox
              picture={blog3}
              title1="Transforming Finance Function with Advanced Analytics"
              date="January 29, 2023  "
              read=" 2 min read"
              class="content-box-section2a"
              redirectURL="/blogs/transforming-finance-function-with-advanced-analytics"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
