import React from "react";
import { useState } from "react";
import trangleImage from "../../assets/img/Infographic -3.png";
import circleImage from "../../assets/img/Infographic-1.png";
import circle from "../../assets/img/Infographic -2.png";

const Section4 = () => {
  const [hover, setHover] = useState(false);

  return (
    <div>
      <div className="Page2Section4-main">
        <div className="Page2Section4-body margin-box ">
          <div className="Page2Section4-top">
            <div className="Page2Sectioin4-left">
              <div className="Page2Section4-text">
                <span style={{ display: "grid" }}>
                  <span style={{ fontSize: "30px", fontWeight: "600" }}>
                    Mastering Data Chaos
                  </span>
                </span>
                <p className="subHeading">
                  Without proper management, data can become unwieldy and
                  inefficient. Our approach empowers organizations to establish
                  a harmonized data governance and analytics framework. We take
                  the chaos out of your data, ensuring it becomes a valuable and
                  controlled asset.
                </p>
              </div>
            </div>
            <div className="Page2Sectioin4-right">
              <div
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className="Page2Section4-image"
              >
                {hover ? (
                  <img loading="lazy" src={circle} alt="" />
                ) : (
                  <img loading="lazy" src={circleImage} alt="" />
                )}
              </div>
            </div>
          </div>
          <div className="Page2Section4-bottom">
            <div className="Page2Sectioin4-left">
              <div className="Page2Section4-image">
                <img loading="lazy" src={trangleImage} alt="" />
              </div>
            </div>
            <div className="Page2Sectioin4-right">
              <div className="Page2Section4-text">
                <span style={{ fontSize: "30px", fontWeight: "600" }}>
                  Unlocking Insights, Enabling Smarter Decision Making{" "}
                </span>
                <p className="subHeading">
                  Explore our Data as a Service (DaaS) ecosystem, where we offer
                  everything from insightful dashboards and reports to
                  cutting-edge AI/ML models. We provide the essential layers
                  that transform raw data into actionable intelligence, ensuring
                  you have the tools to make data-driven decisions confidently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
