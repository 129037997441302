import React from "react";
import Section1 from "../pageLayout/ContactUsLayout/Section1";
import ContactUsBanner from "../assets/img/contact-banner-right.png";
import Navbar from "../nav/NavBar";
import MiddleBanner from "../reuseLayout/MiddleBanner";
import "../pageLayout/ContactUsLayout/ContactUs.scss";
import Section2 from "../pageLayout/ContactUsLayout/Section2";
import Footer from "../nav/Footer";
import { useMediaQuery } from "@mui/material";
import NewHeaderSectionMobile from "./NewHeaderSection/NewHeaderSectionMobile";
import IpadHeaderSection from "./NewHeaderSection/IpadHeaderSection";
import NewHeaderSection from "./NewHeaderSection/NewHeaderSection";
import GoogleAnalytics from "../../app/GoogleAnalytics";


function Contact() {

  const isMobile = useMediaQuery("(max-width: 767px)");
  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  return (
    <div id="homePage" className="contactUs">
      <Navbar />
      <GoogleAnalytics/>

      {
        isMobile ? (
          <NewHeaderSectionMobile  picture={ContactUsBanner}
        text1="Contact Us"
        subtext1="Feel free to keep in"
        subtext2="touch with us!" />
        ) : isIpad ? (
            <IpadHeaderSection  picture={ContactUsBanner}
        text1="Contact Us"
        subtext1="Feel free to keep in"
        subtext2="touch with us!" /> 
          ) : (
              <NewHeaderSection  picture={ContactUsBanner}
        text1="Contact Us"
        subtext1="Feel free to keep in"
        subtext2="touch with us!" />
        )
      }
      
      <MiddleBanner text="Embark on a journey of AI and analytics with us. Whether you have questions, want to collaborate, or need assistance, we're here and ready to assist you"/>

      <div
        style={{
          backgroundColor: "black",
          color: "white",
        }}
      >
        <div
          style={{
            width: "80%",
            margin: "auto",
            display: "block",
            color: "white",
          }}
        >
          <Section1 />
        </div>
        <Section2 />
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
