import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import "react-multi-carousel/lib/styles.css";
import supplyImg1 from "../../assets/img/Supply Chain.png";
import supplyImg2 from "../../assets/img/inventory-case-studies-page-new.png";
import supplyImg4 from "../../assets/img/Innovation Analytics.png";
import RightArrow from "./RightArrow";
import LeftArrow from "./LeftArrow";

const data = [
  {
    id: 1,
    title1: "Supply",
    title2: "Chain",
    content: "How a global CPG conglomerate strengthened its supply chain with optimised supply planning and improved AI-enabled demand forecasting",
    class: "content-box-section2",
    url: "/customer-success-stories/strengthen-supply-chain-supply-planning-demand-forecasting",
    image: supplyImg1,
  },
  {
    id: 2,
    title1: "Inventory",
    title2: "Analytics",
    content: "Inventory transformation and optimization: How Aays helped a global CPG company enhance its inventory management processes",
    class: "content-box-section2",
    url: "/customer-success-stories/inventory-optimization-global-CPG",
    image: supplyImg2,
  },
  {
    id: 3,
    title1: "Innovation",
    title2: "Analytics",
    content: "How a CPG conglomerate leveraged advanced analytics to uncover the value of innovation and assess the impact of new product launches",
    class: "content-box-section2 inventrynew",
    url: "/customer-success-stories/analytics-to-assess-impact-of-innovation",
    image: supplyImg4,
  },
];

function Section2() {
  const [startIndex, setStartIndex] = useState(0);

  const isIpad = useMediaQuery("(min-width: 768px) and (max-width: 1024px)", {
    defaultMatches: false, // Set defaultMatches to false
  });

  const showLength = isIpad ? 2 : 3;
  const visibleItems = isIpad ? data.slice(startIndex, startIndex + 2) : data.slice(startIndex, startIndex + 3);

  return (
    <div
      id="section2-customer-success-story"
      className="customer-success-section2"
      style={{position: 'relative'}}
    >
      <div className="customer-section2-body margin-box">
        <h1 className="success-story-page-section-2-Header-text">
          Supply Chain & Procurement
        </h1>
        <div className="content">
          Discover how our AI solutions have improved supply chains in different ways. Check out examples to see the positive changes we've helped make for global enterprises
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          {data.length > showLength && <LeftArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
          <div className="success-story-page-section-2-grid">
            {visibleItems.map((item) => (
              <div>
                <CustomerSectionBox
                  picture={item.image}
                  title1={item.title1}
                  title2={item.title2}
                  content={item?.content}
                  class={item.class}
                  redirectURL={item.url}
                />
              </div>
            ))}
          </div>
          {data.length > showLength && <RightArrow data={data} startIndex={startIndex} setStartIndex={setStartIndex} />}
        </div>
      </div>
    </div>
  );
}

export default Section2;
