import React from "react";
import { useMediaQuery, useTheme } from "@mui/material/";
import Section2Image from "../../assets/img/about-section2.png";
import MobileSection2Image from "../../assets/img/about-section2-mobile.png";

function Section2() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="about-section2 padding-both">
      <h1 className="header-text"> Our Solutions</h1>
      <div
        style={{ width: "70%", margin: "auto", display: "block" }}
        className="content"
      >
        At Aays, we seamlessly traverse the entire spectrum of Data and AI,
        starting with the establishment of robust architecture to the generation
        of valuable insight
      </div>

      {isMobile ? (
        <div className="section2-image-div">
          <img
            loading="lazy"
            style={{ width: "100%", margin: "auto" }}
            src={MobileSection2Image}
            alt="solution"
          />
        </div>
      ) : (
        <div className="section2-image-div">
          <img
            loading="lazy"
            style={{ width: "80%", padding: "4rem", margin: "auto" }}
            src={Section2Image}
            alt="solution"
          />
        </div>
      )}
    </div>
  );
}

export default Section2;
