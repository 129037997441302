import React from "react";
import ParticleBackground from "./Network";
import background from "../../assets/img/test-animation-background.png";
import bottomImage from "../../assets/img/test-animation-grid.png";
function NewHeaderSection(props) {
  return (
    <>
      <div
        className="test-animation section1"
        style={{
          height: "calc(100vh - 58px)",
          backgroundImage: `url(${background})`,
          position: "relative",
          backgroundSize: "cover"
        }}
      >
        <div className="main-container">
          <div className="grid-container">
            <div className="left">
              <div className="header_section_1">
                <div>
                  <p className="firstText">
                    {props.text1 && (
                      <>
                        {props.text1} <br />
                      </>
                    )}
                    {props.text2 && (
                      <>
                        {props.text2} <br />
                      </>
                    )}
                    {props.text3 && (
                      <>
                        {props.text3} <br />{" "}
                      </>
                    )}
                    {props.text4 && <>{props.text4}</>}
                  </p>

                  <p className="secondText">
                    {props.subtext1} <br /> {props.subtext2}{" "}
                  </p>
                </div>
                <div className=".header_section_2"></div>
              </div>
            </div>
            <div className="right">
              <img src={props.picture} alt="right-imgae" />
            </div>
          </div>
        </div>
        <div className="bottom">
          <img src={bottomImage} alt="right-imgae" />
        </div>
        <ParticleBackground />
      </div>
    </>
  );
}

export default NewHeaderSection;
